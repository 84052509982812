<template>
	<main id="Text">
		<div class="center-text">
      <br>
		<h1>SunBEAm-ABC investigators</h1>
    <br>
		<!-- <img src="../assets/img/team/overall.png" width="600px"> -->
    <div>
    <table>
        <thead>
          <tr>
            <!-- <th>Photo</th>
            <th>Name</th> -->
			<!-- <th>Role</th> -->
            <!-- <th>Email</twh> -->
          </tr>
        </thead>
        <tbody>
          <tr class="center-photos" align="center">
             <td v-for="(user, index) in pis" :key="index">
                <div class="list-complete-item">
                    <a target="_blank" v-bind:href="user.website">  
                <img class="image" :src="user.profileImage" alt="Profile Image">
                
                      <div class="overlay">
                        
                          <div class="hover-text">{{ user.affiliation }}</div>
                        
                      </div>
                    
                    </a>
                </div>
                <p>
                  <a class="ppl-org-names" target="_blank" v-bind:href="user.website"> 
                  {{ user.name }}
                </a>
                </p>
             </td>
          </tr>
        </tbody>
      </table>
  </div>
  </div>
  <br><br>
  <div align="center">
    
		<h1>Staff</h1>
    <br>
		<!-- <img src="../assets/img/team/overall.png" width="600px"> -->
    <table>
        <thead>
          <tr>
            <!-- <th>Photo</th>
            <th>Name</th> -->
			<!-- <th>Role</th> -->
            <!-- <th>Email</twh> -->
          </tr>
        </thead>
        <tbody>
          <tr class="single-photo" align="center">
             <td v-for="(user, index) in staffs" :key="index">
                <div class="list-complete-item">
                    <a target="_blank" v-bind:href="user.website">  
                <img class="image" :src="user.profileImage" alt="Profile Image">
                
                      <div class="overlay">
                        
                          <div class="hover-text">{{ user.affiliation }}</div>
                        
                      </div>
                    
                    </a>
                </div>
                <p>
                  <a class="ppl-org-names" target="_blank" v-bind:href="user.website"> 
                  {{ user.name }}
                </a>
                </p>
             </td>
          </tr>
        </tbody>
      </table>
  </div>
  <br><br>
  <div align="center">

    <!-- <div id="test"></div> -->
		<h1>Collaborators</h1>
    <br>
		<!-- <img src="../assets/img/team/overall.png" width="600px"> -->
<!-- 
    <div class="content">
    <div class="goal-text" align="left"> -->
   <table class="center-one-each-row">
        <thead>
          <tr>
          </tr>
        </thead>
        <tbody>

            <tr v-for="(collab, index) in collaborators" :key="index">
              <!-- <a target="_blank" v-bind:href="collab.website"> 
              <p align="center">{{ collab.name }}</p> 
              </a> -->
              <td>
                <a v-if="collab.profileImage !== 'null'" target="_blank" v-bind:href="collab.website"> 
                
                <img align="center" class="logo" :src="collab.profileImage" alt="Profile Image">
                  <!-- <p class="ppl-org-names" align="center">{{ collab.name }}</p> -->
                  <p class="ppl-org-names" align="center">{{ collab.name }}</p>
                </a>
                <a v-if="collab.profileImage === 'null'" target="_blank" v-bind:href="`${collab.website}`"> 
                  <p class="ppl-org-names-smaller" align="center" target="_blank">{{ collab.name }} <br></p>
                </a>

                    
              <!-- <div class="list-complete-item">
                    <a target="_blank" v-bind:href="collab.website">  
                <img align="center" class="logo" :src="collab.profileImage" alt="Profile Image">
                
                      <div class="overlay">
                        
                          <div class="hover-text">{{ collab.name }}</div>
                        
                      </div>
                    
                    </a>
                </div> -->
              </td>
          </tr>
          
        </tbody>
      </table>
      <!-- </div>
      <div style="flex-grow: 1" align="center">
    <img src="../assets/img/figureB1_gears.jpg" width="600px">
  </div>
      </div> -->
  </div>
	</main>
</template>

<script>
export default {
    name: "team",

    data() {
		return {
		//   picpath: ),
          
          pis: [
            {
              name: "Supinda Bunyavanich",
              affiliation: "Icahn School of Medicine at Mount Sinai",
              profileImage: require("../assets/img/team/sbunyavanich.jpeg"),
              website: "http://bunyavanich.org/"
            },
            {
              name: "Cecillia Berin",
              affiliation: "Northwestern University",
              profileImage: require("../assets/img/team/cberin.jpeg"),
              website: "https://www.theberinlab.org/"
            },
            {
              name: "Donald Leung",
              affiliation: "National Jewish Health",
              profileImage: require("../assets/img/team/dleung.jpeg"),
              website: "https://www.nationaljewish.org/doctors-departments/providers/physicians/donald-y-m-leung"
            },
            {
              name: "Gaurav Pandey",
              affiliation: "Icahn School of Medicine at Mount Sinai",
              profileImage: require("../assets/img/team/gpandey2.jpeg"),
              website: "https://gpandeylab.org/"
            },
            {
              name: "Eric Schadt",
              affiliation: "Icahn School of Medicine at Mount Sinai",
              profileImage: require("../assets/img/team/eschadt.jpeg"),
              website: "https://icahn.mssm.edu/profiles/eric-schadt"
            },
            {
              name: "Robert Wood",
              affiliation: "Johns Hopkins University",
              profileImage: require("../assets/img/team/rwood.jpeg"),
              website: "https://www.hopkinsmedicine.org/profiles/details/robert-wood"
            },
            {
              name: "Corinne Keet",
              affiliation: "University of North Carolina Chapel Hill",
              profileImage: require("../assets/img/team/ckeet.png"),
              website: "https://www.unchealth.org/care-services/doctors/k/corinne-allison-keet-md-phd.html"
            },
            {
              name: "Scott Sicherer",
              affiliation: "Icahn School of Medicine at Mount Sinai",
              profileImage: require("../assets/img/team/ssicherer.jpeg"),
              website: "https://profiles.mountsinai.org/scott-h-sicherer"
            },
            {
              name: "Pei Wang",
              affiliation: "Icahn School of Medicine at Mount Sinai",
              profileImage: require("../assets/img/team/pwang.jpeg"),
              website: "https://labs.icahn.mssm.edu/pei-wang-lab/"
            },
            {
              name: "Kristin Beaumont",
              affiliation: "Icahn School of Medicine at Mount Sinai",
              profileImage: require("../assets/img/team/kbeaumont.jpeg"),
              website: "https://icahn.mssm.edu/profiles/kristin-g-beaumont"
            },
            {
              name: "Zeynep H. Gümüş",
              affiliation: "Icahn School of Medicine at Mount Sinai",
              profileImage: require("../assets/img/team/zgummus.jpeg"),
              website: "https://gumuslab.github.io/"
            },
            {
              name: "Elena Goleva",
              affiliation: "National Jewish Health",
              profileImage: require("../assets/img/team/egoleva.jpeg"),
              website: "https://www.nationaljewish.org/doctors-departments/providers/physicians/elena-goleva"
            },
            {
              name: "Evgeny Berdyshev",
              affiliation: "National Jewish Health",
              profileImage: require("../assets/img/team/eberdyshev.jpeg"),
              website: "https://www.nationaljewish.org/doctors-departments/providers/researchers/evgeny-v-berdyshev"
            },

			// {
      //         name: "Yan Chak Li",
      //         role: "Bioinformatician",
      //         profileImage: require("../assets/img/team/ycli.jpeg"),
      //       }
          ],
          staffs: [
                    {
                      name: 'Yan Chak Li',
                      affiliation: "Icahn School of Medicine at Mount Sinai",
                      website: "https://huhrichard.github.io/",
                      // staff_list: ["Yan Chak Li"]
                      profileImage: require("../assets/img/team/ycli.jpeg"),
                    },
                  ],
          collaborators: [
                      {
                        name: "SunBEAm Steering Group",
                        // affiliation: "Icahn School of Medicine at Mount Sinai",
                        profileImage: require("../assets/SunBEAm_logo.png"),

                      },
                      {
                        name: "National Institute of Allergy and Infectious Diseases (NIAID)",
                        // affiliation: "Northwestern University",
                        profileImage: require("../assets/img/team/niaid.png"),
                        website: "https://www.niaid.nih.gov/"
                      },
                      {
                        name: "(SunBEAm biosample and data access policy)",
                        // affiliation: "Northwestern University",
                        profileImage: "null",
                        // font: "smaller",
                        website: "./pdf/access-policy-to-sunbeam-biosamples-and-data-v2-06-nov-2023.pdf"
                      },

                      {
                        name: "NIAID Allergy and Asthma Statistical and Clinical Coordinating Center",
                        // affiliation: "Northwestern University",
                        profileImage: require("../assets/img/team/Rho_logo.png"),
                        website: "https://www.rhoworld.com/federal-project-pages/nih-niaid-dait-statistical-and-clinical-coordinating-center/"
                      },
                      {
                        name: "Immune Tolerance Network",
                        // affiliation: "Northwestern University",
                        profileImage: require("../assets/img/team/ITN-Logo.jpeg"),
                        website: "https://www.immunetolerance.org/"
                      },
                      {
                        name: "Atopic Dermatitis Research Network",
                        // affiliation: "Northwestern University",
                        profileImage: require("../assets/img/team/adrn-logo.jpeg"),
                        website: "https://www.nationaljewish.org/research-science/clinical-and-translational-research/adrn/overview"
                      },          
                  ],
          publicPath: process.env.BASE_URL

        };
    },
    methods: {
      openPdf(){
        window.open("../public/access-policy-to-sunbeam-biosamples-and-data-v2-06-nov-2023.pdf") 
      }
    },
    // computed: {
    // imageRows() {
    //   imgWidth_total = 180*13;
    //   current_width = window.innerWidth;
  //     const rows = [];
  //     let currentRow = [];
  //     let currentRowWidth = 0;

  //     for (const image of this.images) {
  //       const img = new Image();
  //       img.src = image;
  //       img.onload = () => {
  //         const imgWidth = img.width;
  //         if (currentRowWidth + imgWidth <= this.$el.offsetWidth) {
  //           currentRow.push(image);
  //           currentRowWidth += imgWidth;
  //         } else {
  //           rows.push(currentRow);
  //           currentRow = [image];
  //           currentRowWidth = imgWidth;
  //         }
  //       };
  //     }

  //     if (currentRow.length > 0) {
  //       rows.push(currentRow);
  //     }

  //     return rows;
  //   },
  // },
  
}

</script>

<style scoped>


.center-text {
  font-size: min(2vm, 30px);
  /* position: center; */
  /* text-align: center; */
  /* align-items: center; */
  padding-left: 12%; 
  padding-right: 12%;
  display: block;
}

.center-one-each-row {
  margin-left: auto;
  margin-right: auto;

}

.center-photos {
  /* margin-left: auto;
  margin-right: auto; */
  /* padding: 0; */
  /* padding-left: 11%; 
  padding-right: 11%; */
    /* margin-left: auto;
    margin-right: auto;
    list-style-type: none; */
    /* display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; */
    display: flex;
    
    /* -webkit-flex-flow: row wrap; */
    /* flex-direction: row wrap; */
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
}

.single-photo {

}


.hover-text {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.list-complete-item {
  position: relative;
  width: 11vw;
  min-width: 160px;
  max-width: 200px;
  aspect-ratio: 180 / 235;
  /* font-size: 1.3em; */
}

.list-complete-item .image {
    /* /* display: block; */
    width: 11vw;
  min-width: 160px;
  max-width: 200px;
  aspect-ratio: 180 / 235;
    /* height: 235px; */
    object-fit: cover; 
    object-position: 50% 50%;
}

.logo {
    display: block;
    height: 5vh;
    min-height: 100px;
    max-height: 130px;
    margin-left: auto;
    margin-right: auto;
    /* object-fit: cover; 
    object-position: 50% 50%; */
}

.list-complete-item .overlay, .list-complete-item .image, .overlay {
  width: 11vw;
  min-width: 160px;
  max-width: 200px;
  aspect-ratio: 180 / 235;
    /* width: auto; */
    /* height: auto; */
  /* height: 235px; */
}
.list-complete-item .overlay {
    /* position: absolute; */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* height: 100%;
    width: 100%; */
    width: 11vw;
  min-width: 160px;
  max-width: 200px;
  aspect-ratio: 180 / 235;
    object-position: 50% 50%;
    opacity: 0;
    transition: .5s ease;
    background-color: #1F497E;
    /* background-color: #008CBA; */
}
.list-complete-item:hover .overlay {
    opacity: 0.8;
}

.ppl-org-names{
  color: #219EBC;
  /* font-size: max(0.9vw, 20px); */
  font-size: max(0.9vw, 20px);
}

.ppl-org-names-smaller{
  color: #219EBC;
  /* font-size: max(0.9vw, 20px); */
  font-size: max(0.7vw, 16px);
}

.centered-container {
  /* font-family: arial;
  font-size: 24px;
  margin: 25px;
  width: 350px;
  height: 200px;
  outline: dashed 1px black; */
  /* Center child horizontally*/
  display: flex;
  justify-content: center;
}

.overlay {
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  /* -webkit-transform: translate(-50%, -50%); */
  /* transform: translate(20%, 0);
  
  opacity: 0; */
  margin-left: auto;
  margin-right: auto;
  transition: 0.5s ease;
  background-color: #1F497E;
  /* background-color: #008cba; */
}

.content {
  display: flex;

  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.center-photos>td{
  flex: 1 0 13vw;
}

/* .a href {
  
} */

</style>
<!-- <script type="module">
    const app = Vue.createApp({
      data() {
        
      }
    });
    app.mount('#app');
</script> -->

