<template>
  <div class="correlation-container">
    <correlation
        :tracksX="tracksX" 
        :trackDataX="trackDataX"
        :tracksY="tracksY" 
        :trackDataY="trackDataY"
      />
    
  </div>
</template>

<script>
import Correlation from './CorrelationMap.vue'

export default {
    components: { Correlation },
    name: "correlation-container",

    props: ['view'],

    data: () => ({
    }),
    
    computed: {
        useZScore() { return false },
        tracksX() {
          console.log('test correlation xtracks')
          console.log(this.useZScore)
          return this.$store.state.Correlation_tracks['x']},
        trackDataXNormalized() {return this.$store.state.Correlation_data['x'] },
        trackDataXRaw() {return this.$store.state.Correlation_data_raw['x'] },
        trackDataX() { 
          console.log('test correlation xtracks2')
          return this.useZScore ?
          this.trackDataXNormalized :
          this.trackDataXRaw
        },
        tracksY() {
          console.log('test correlation ytracks')
          return this.$store.state.Correlation_tracks['y']},
        trackDataYNormalized() {return this.$store.state.Correlation_data['y'] },
        trackDataYRaw() {return this.$store.state.Correlation_data_raw['y'] },
        trackDataY() { 
          console.log('test correlation ytracks2')
          return this.useZScore ?
          this.trackDataYNormalized :
          this.trackDataYRaw
        },
        // trackData() { return this.useZScore ?
        //   this.trackDataNormalized :
        //   this.trackDataRaw
        // },
    //     
    },
    mounted() {
    },
  }
</script>

<style>
.correlation-container {
    /* width: 100%; */
    /* left: 20px; */
    padding-left: 5%;
    
    /* right: 0px; */
}
</style>