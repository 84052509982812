<template>
    <div class="barplotexposure"> 
      
      <div :id="divId">
      </div>
    </div>
</template>

<script>
  // import sampleMeta from '../refs/sampleMeta'
  import sampleMeta from '../../refs/sampleMetaNasal'
  import generateBarplotExposure from '../../plotly/barplotlyExposure'

  export default {
    name: "barplotexposure",

    props: [ 
      'track', 
    'trackData', 
    'dataKeys'],

    data() {
      return {
      }
    },

    computed: {
      divId() { 
        console.log('test barplot4')
        return `${this.track}-barplot` 
      },

      // percentView() { return true },
    },

    watch: {
      trackData() { this.barplot() },
      // percentView() { this.barplot() },
    },

    methods: {
      barplot() {
        console.log('barplot vue')
        generateBarplotExposure({
          divId: this.divId,
          percentView: true,
          sampleMeta,
          trackData: this.trackData,
          track: this.track,
          dataKeys: this.dataKeys,
        })
      }
    },

    mounted() {
      this.barplot()
    },
  }

</script>

<style scoped>
    .barplot-exposure {
      /* border-top: solid 1px #ededed; */
      width: 100%;
      min-width: 340px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
</style>