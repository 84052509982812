// import correlation from "calculate-correlation/lib/correlation";

const calculateCorrelation = require("calculate-correlation")

// function corr(x, y) {
//     let sumX = 0,
//       sumY = 0,
//       sumXY = 0,
//       sumX2 = 0,
//       sumY2 = 0;

//     const minLength = x.length = y.length = Math.min(x.length, y.length),
//       reduce = (xi, idx) => {
//         const yi = y[idx];
//         sumX += xi;
//         sumY += yi;
//         sumXY += xi * yi;
//         sumX2 += xi * xi;
//         sumY2 += yi * yi;
//       }
    
//     x.forEach(reduce);

//     return (minLength * sumXY - sumX * sumY) /
//       Math.sqrt((minLength * sumX2 - sumX * sumX) * (minLength * sumY2 - sumY * sumY));
//   }

function filter_non_nan_xy(_x, _y){
    var non_na_xidx = _x.map(i => i !== undefined)
    var non_na_yidx = _y.map(i => i !== undefined)

    var non_na_xyidx = non_na_xidx.map((e, i) => e & non_na_yidx[i])
    var _non_na_x = _x.filter((e, i) => non_na_xyidx[i])
    var _non_na_y = _y.filter((e, i) => non_na_xyidx[i])
    var non_nan_xy = {};
    non_nan_xy['x'] = _non_na_x
    non_nan_xy['y'] = _non_na_y
    return non_nan_xy
}

export default function corrvis({
    divId,
    trackDataX,
    tracksX,
    trackDataY,
    tracksY
}) {

    let zValues = [];
    console.log('test corrvis 0')
    // console.log(x_data)
    // Uncomment to debug: console.log("DUMMIES", dummies);
    let x_data = trackDataX[tracksX]
    let y_data = trackDataY[tracksY]
    console.log('test corrvis 1')
    console.log(trackDataX[tracksX[0]])
    let xLength = tracksX.length;
    let yLength = tracksY.length;
    
    for (let j = 0; j < yLength; j++) {
        let y_list = trackDataY[tracksY[j]];
    // Uncomment to debug: 
        console.log("COMPARING", y_list['data']);
        let correlations = [];

        for (let i = 0; i < xLength; i++) {
            let x_list = trackDataX[tracksX[i]];
    //     // Uncomment to debug: 
            // console.log("TO", y_list['data']);
            const samples = Object.keys(x_list.data).filter(s => Object.keys(y_list.data).includes(s))
            
            const x = samples.map(sample => x_list.data[sample])
            const y = samples.map(sample => y_list.data[sample])

            var non_nan_xy = filter_non_nan_xy(x, y)
            const non_na_x = non_nan_xy.x
            const non_na_y = non_nan_xy.y
            console.log(non_na_x)
            console.log(non_na_y)
            const pearsonCorrelation = calculateCorrelation(non_na_x, non_na_y).toFixed(3)


            // let pearsonCorrelation = corr(_x,_y).toFixed(2)

            correlations.push(
            pearsonCorrelation
            );
            console.log(pearsonCorrelation)
        }

        zValues.push(correlations);
    }
    console.log('zvals', zValues)
    console.log(xLength, yLength)
    // var xValues = Object.keys(dataframe_X);
    // var yValues = Object.keys(dataframe_X);

    var colorscaleValue = [
        [0, '#3D9970'],
        [1, '#001f3f']
    ];

    var data = [{
        x: tracksX,
        y: tracksY,
        z: zValues,
        type: 'heatmap',
        colorscale: colorscaleValue,
        showscale: true
    }];

    var layout = {
                    title: 'Heatmap of correlations',
                    autosize: false,
                    // width: window.innerWidth - 500,
                    // height: 700,
                    annotations: [],
                    xaxis: {
                        ticks: '',
                        side: 'top',
                        automargin: true
                    },
                    yaxis: {
                        ticks: '',
                        ticksuffix: ' ',
                        automargin: true
                        // autosize: false
                    },
                    margin: {
                        l: 152,
                    }
                };
    let textColor = 'white';
    for (var i = 0; i < yLength; i++) {
        for (var j = 0; j < xLength; j++) {
            var currentValue = zValues[i][j];
            // if (currentValue != 0.0) {
            //     textColor = 'white';
            // } else {
            //     textColor = 'black';
            // }
            textColor = 'white';
            var result = {
            xref: 'x1',
            yref: 'y1',
            x: tracksX[j],
            y: tracksY[i],
            text: zValues[i][j],
            // font: {
            //     family: 'Arial',
            //     size: 12,
            //     color: 'rgb(50, 171, 96)'
            // },
            showarrow: false,
            font: {
                family: 'Arial',
                size: 35/Math.sqrt(Math.max(xLength, yLength)),
                color: textColor
            }
            };
            layout.annotations.push(result);
        }
    }
    console.log(data);
    const Plotly = window.Plotly
    Plotly.newPlot(divId, data, layout);
}