import ttest from 'ttest'


export default function generateBoxplot({
  divId,
  plotData
}) {
  console.log('testboxploly')  
  // const ttest_option = {valEqual: false,
  //                 }  
    if (!plotData) { return }
    // console.log(trackData)
    const Plotly = window.Plotly
    let color_plot = ''
    if (plotData['Coefficient'] > 0){
      color_plot = '#DC3220'
    } else {
      color_plot = '#005AB5'
    }
      

    let air_toxic_groups = [[], [], [], []]
    let air_toxic_levels = [1, 2, 3, 4]
    let quantiles = ['1st', '2nd', '3rd', '4th']

    let len_p = plotData['AirToxicVal'].length
    for (let idx = 0; idx < len_p; idx++) {
      let air_toxic_level = parseInt(plotData['AirToxicVal'][idx])-1
      air_toxic_groups[air_toxic_level].push(plotData['AdjMeth'][idx])
    }
    // plotData['AirToxicVal'].forEach((p) => {
    //   air_toxic_level = parseInt(p)-1
    //   air_toxic_groups[air_toxic_level].push(p['AdjMeth'])
    // })
    console.log('boxplot cpg check', air_toxic_groups)

    let trace_list = []
    
    for (let i = 0; i < 4; i++) {
      let trace_temp = {
        y: air_toxic_groups[i],
        name: quantiles[i],
        marker: {
          color: color_plot
        },
        type: 'box',
        boxpoints: 'all',
        jitter: 0.4,
        pointpos: 0,
        // text: groupASamples
      }
      trace_list.push(trace_temp)
    }
    // console.log('boxplot cpg check 2', trace_list)
    
  //   let trace1 = {
  //     y: traceA,
  //     name: groupA.label,
  //     marker: {
  //       color: '#DC3220'
  //     },
  //     type: 'box',
  //     boxpoints: 'all',
  //     jitter: 0.4,
  //     pointpos: 0,
  //     text: groupASamples
  //   }

  //   let trace2 = {
  //     y:  traceB,
  //     name: groupB.label,
  //     marker: {
  //       color: '#005AB5'
  //     },
  //     type: 'box',
  //     boxpoints: 'all',
  //     jitter: 0.4,
  //     pointpos: 0,
  //     text: groupBSamples
  //   }
    
  //   var suffixes = {
  //     'rnaSeq': 'raw count',
  //     'exposure': 'microgram/m^3'
  //     // 'rna': 'TPM',
  //     // 'phospho': 'Log2 intensity',
  //     // 'proteo': 'Log2 intensity',
  //     // 'glyco_glyco': 'Log2 intensity',
  //     // 'phospho_glyco': 'Log2 intensity',
  // }
  //   if (geneCpm) {
  //     suffixes['rnaSeq'] = 'CPM'
  //   }
    // const dataType = track.split(' ')[1]  
    let y_axis_title = plotData['CpGName']
    if (!(plotData['GeneSymbol'] === null)) {
      y_axis_title = y_axis_title + '<br>(' + plotData['GeneSymbol'] + ')'
    }

    y_axis_title = y_axis_title + '<br>(adjusted methylation values)'

    // let y_axis_title = plotData['CpGName'] + "\n(Adjusted)"
    let plottitle = ''
    if (plotData['p_value'] === null) {
      plottitle = ''
    } else {
      let pValue =  plotData['p_value'].toExponential().toString()
      if (pValue.includes('e')) {
        const pValArr = pValue.split('e')
        const val = pValArr[0].slice(0,4)
        const exp = pValArr[1]
        pValue = `${val}e${exp}`
      }
      plottitle = 'p-value = ' + pValue
    }

    let layout = {
      title: plottitle,
      yaxis: {
        title: y_axis_title,
        zeroline: false,
        hoverformat: '.2f'
      },
      xaxis: {
        title: 'Quartiles of '+ plotData['AirToxicName'],
      },
      boxmode: '',
    };

    console.log('check boxplotcpgjs', divId)
    var elementExists = document.getElementById(divId);
    console.log('dom exist?', elementExists)
    let config = {
      responsive: true,
    }

    Plotly.newPlot(divId, trace_list, layout, config);
}