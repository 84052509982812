<template>
    <div class="correlation">
        <!-- test -->
        <div :id="divId">
        </div>
    </div>
    <!-- <div class="correlation">
        <div :id="divId">
        </div>
    </div> -->
  </template>
  
  <script>
//   import correlation from '../plotly/correlationly'
import corrvis from '../../plotly/corrmap'
  
  export default {
      name: "correlation",
  
      props: [  
    //   'dataKeys', 
      'tracksX',
      'trackDataX',
      'tracksY',
      'trackDataY', 
    //   'dataType'
    ],
      
      data: () => ({
          view: 'Correlation',
      }),
  
      computed: {
        //   stratifyBy() { 
        //       console.log('st_by')
        //       return this.$store.state.Correlation_stratifyBy },
          divId() { 
              console.log('usds'.split(' ').join('-'))
              return `${this.view}-vis` 
            },
        //   sampleMeta() { return this.$store.state.sampleMeta },
        //   logScale() { return false }, 
        //   dataType() {return 'exposure'},
      },
  
      watch: {
        //   stratifyBy() { this.generateCorrelation() },
          tracksX() { this.generateCorrelation() },
          trackDataX() { this.generateCorrelation() },
          tracksY() { this.generateCorrelation() },
          trackDataY() { this.generateCorrelation() },
        //   dataKeys() { this.generateCorrelation() },
      },
  
      methods: {
          generateCorrelation() {
              console.log('gen correlation') 
            //   if (!this.dataKeys) { return }
              corrvis({
                  divId: this.divId,
                  trackDataX: this.trackDataX,
                  tracksX: this.tracksX,
                  trackDataY: this.trackDataY,
                  tracksY: this.tracksY,
                //   dataKeys: this.dataKeys,
                //   stratifyBy: this.stratifyBy,
                //   useZScore: this.useZScore,
                //   logScale: this.logScale,
                //   dataType: this.dataType,
              })
          }
      },
  
      mounted() { this.generateCorrelation() }
  }
  </script>
  
  <style>
  .correlation {
    /* margin-left: auto; */
    right: 0px;
      /* width: 100%; */
      min-width: 400px;
  }
  </style>