<template>
  <div class="input-barplot-pred">
    <v-autocomplete
        v-model="barplotExposures"
        :items="exposures"
        outlined
        chips
        deletable-chips
        small-chips
        :menu-props="{closeOnContentClick: true}"
        dense
        :label="`Select up to ${maxExposures} air toxics`"
        multiple
        clearable
    >
  </v-autocomplete>
    <v-autocomplete
        v-model="barplotGenes"
        :items="genes"
        outlined
        chips
        deletable-chips
        small-chips
        :menu-props="{closeOnContentClick: true}"
        dense
        :label="`Select up to ${maxGenes} genes`"
        multiple
        clearable
    >
    </v-autocomplete>
    <div
      @click="submitBarplotPred"
    >
    <v-btn
        v-if="barplotExposures.length > 0 || barplotGenes.length > 0"
        :loading="loaderBarplot"
        :disabled="loading || !valid"
        color="blue-grey"
        class="ma-2 white--text"
      >
        
        {{ valid ? 'Render barplots' : 'Select fewer air toxics/genes' }}
    </v-btn>
    </div>
  </div>
</template>

<script>
// import InputBarplotCustomize from './InputBarplotCustomize.vue'

export default {
    name: "input-barplot-pred",

    computed: {
        availableExposure() { 
          return this.$store.state.availableExposurePred 
        },
        availableGene() { 
          return this.$store.state.availableGenePred 
        },
        loaderBarplot() { return this.$store.state.loaderBarplot },

        exposures() { 
            const available = Object.keys(this.availableExposure)
            available.sort()
            return available
        },

        genes() { 
            const available = Object.keys(this.availableGene)
            available.sort()
            return available
        },
        valid() { 
          const exposuresValidBool = this.barplotExposures.length <= this.maxExposures && this.barplotExposures.length > 0
          const genesValidBool = this.barplotGenes.length <= this.maxGenes && this.barplotGenes.length > 0
          console.log(exposuresValidBool)
          return  exposuresValidBool || genesValidBool
        },
    },
    
    data: () => ({
        barplotExposures: ['PHOSPHINE'],
        loading: false,
        maxExposures: 6,
        barplotGenes: ['CD69'],
        maxGenes: 6,
    }),


    methods: {
        submitBarplotPred() {
            console.log('testSubmitBarplotPred')
            // this.$store.dispatch('setBarplotExposure',{
            //   view: 'BarplotPred', 
            //   exposures: this.barplotExposures
            // })
            this.$store.dispatch('setViewAttr',{
              view: 'BarplotPred', 
              attrKey: 'exposures',
              attrVal: this.barplotExposures
            })
            this.$store.dispatch('setViewAttr',{
              view: 'BarplotPred', 
              attrKey: 'genes',
              attrVal: this.barplotGenes
            })
        },
    },

    mounted() { this.submitBarplotPred() }
}
</script>

<style>
.input-barplot {
    margin: 10px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.ttest-descriptor {
  width: 100%;
  margin: 10px;
  font-size: 0.8em;
}
</style>