<template>
    <div class="input-container">
        <view-selector />
        <input-barplot :style="showBarplot" />
        <input-barplot-pred :style="showBarplotPred" />
        <input-sample-dashboard :style="showSampleDashboard" />
        <input-heatmap :style="showHeatmap" />
        <input-correlation-map :style="showCorrelation" />
        <!-- <input-boxplot-gene :style="showBoxplotGene" />
        <input-boxplot-exposure :style="showBoxplotExposure" /> -->
        <input-boxplot :style="showBoxplot" />
        <input-boxplot-cpg :style="showBoxplotCPG" />
        <input-network-vis2 :style="showNetwork" />
        <input-scatterplot :style="showScatterplot" />
        <!-- <input-quality-control :style="showQualityControl" /> -->
        <!-- <input-histology-viewer :style="showHistologyViewer" /> -->
    </div>
</template>

<script>
// import InputBoxplot from './InputBoxplot.vue'
// import InputBoxplotGene from './InputBoxplotGene.vue'
// import InputBoxplotExposure from './InputBoxplotExposure.vue'
import InputBoxplot from './Boxplot/InputBoxplot.vue'
import InputBoxplotCpg from './Boxplot/InputBoxplotCpg.vue'
import InputBarplot from './Barplot/InputBarplot.vue'
import InputBarplotPred from './Barplot/InputBarplotPred.vue'
import InputHeatmap from './Heatmap/InputHeatmap.vue'
import InputCorrelationMap from './Correlation/InputCorrelationMap.vue'
import InputNetworkVis2 from './InputNetworkVis2.vue'

// import InputHistologyViewer from './InputHistologyViewer.vue'
// import InputQualityControl from './InputQualityControl.vue'
import InputSampleDashboard from './SampleDashboard/InputSampleDashboard.vue'
import InputScatterplot from './Scatterplot/InputScatterplot.vue'
import ViewSelector from './ViewSelector.vue'

export default {
    components: {
        // InputBoxplotGene,
        // InputBoxplotExposure,
        InputHeatmap,
        InputBoxplot,
        InputBoxplotCpg,
        InputBarplot,
        // InputBarplotPred,
        InputCorrelationMap,
        InputNetworkVis2,
        // InputHistologyViewer,
        // InputQualityControl,
        InputSampleDashboard,
        InputScatterplot,
        InputBarplotPred,
        ViewSelector,
    },
    name: "input-container",
    computed: {
        // showBoxplotGene() { return this.view === 'BoxplotGene' ? "" : "display: none;" },
        // showBoxplotExposure() { return this.view === 'BoxplotExposure' ? "" : "display: none;" },
        showBoxplot() { return this.view === 'Boxplot' ? "" : "display: none;" },
        showBoxplotCPG() { 
            console.log('boxplotcpg', this.view)
            return this.view === 'BoxplotCPG' ? "" : "display: none;" },
        showBarplot() { 
            // console.log(this.view)
            // console.log(this.$store.state.availableExposureQuantile)
            return this.view === 'Barplot' ? "" : "display: none;" },
        showBarplotPred() { 
            console.log(this.view)
            // console.log(this.$store.state.availableExposureQuantile)
            return this.view === 'BarplotPred' ? "" : "display: none;" },
        // showDemo() { return this.view === 'Demo' ? "" : "display: none;" },
        showHeatmap() { return this.view === 'Heatmap' ? "" : "display: none;" },
        // showIprofun() { return this.view === 'Iprofun' ? "" : "display: none;" },
        showSampleDashboard() { return this.view === 'Sample dashboard' ? "" : "display: none;" },
        showScatterplot() { return this.view === 'Scatterplot' ? "" : "display: none;" },
        showCorrelation() { return this.view === 'Correlation' ? "" : "display: none;" },
        showNetwork() { return this.view === 'Network' ? "" : "display: none;" },
        // showQualityControl() { return this.view === 'Quality control' ? "" : "display: none;" },
        // showHistologyViewer() { return this.view === 'Histology viewer' ? "" : "display: none;" },
        view() { return this.$store.state.view },
    }
}
</script>

<style scoped>
    .input-container {
        max-width: 350px;
        min-width: 350px;
        border-top: none;
        padding-top: 8px;
        overflow-y: scroll;
        border-right: solid 1px lightgray;
}

    .input-container > div {
        margin-bottom: 1em;
    }

    .mssm-logo {
        margin: 2em;
    }

    .logo {
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: fixed;
        bottom: 0;
        /* left: 100px; */
    }
</style>