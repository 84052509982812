<template>
  <div class="barplot-pred-container">
    &nbsp;Barplot of exposure(s) vs predicted AR-DX
      <barplotpred
        v-for="(dataKeys, track) in k_track_v_barplotDataKeysExposure"
        :key="track"
        :track="track" 
        :trackData="trackDataExposure"
        :dataKeys="dataKeys"
      />

      &nbsp;Barplot of gene(s) vs predicted AR-DX
      <barplotpred
        v-for="(dataKeys, track) in k_track_v_barplotDataKeysGene"
        :key="track"
        :track="track" 
        :trackData="trackDataGene"
        :dataKeys="dataKeys"
      />

  </div>
</template>

<script>
import Barplotpred from './BarplotPred.vue'
// import Barplotgene from './BarplotGene.vue'
// import Barplot from './Barplot.vue'

export default {
    components: { Barplotpred },
    name: "barplot-pred-container",

    props: ['view'],

    data: () => ({
    }),
    
    computed: {
      
        k_track_v_barplotDataKeysExposure() { 
          console.log('testbarplotpred3')
          console.log('test stored', this.$store.state.availableExposurePred)
          let res = {}

          this.exposures.forEach(exposure => {
            let exposure_str = exposure + '_' + exposure
            let exposure_pred_str = exposure + '_pred_cls'
            let exposure_cols = [exposure_str, exposure_pred_str]
            res[exposure] = exposure_cols
            
          })
          // console.log(this.exposures)
          console.log('before generate')
          console.log(res)
          console.log(this.trackDataExposure)
          // console.log()
          return res
        },
        k_track_v_barplotDataKeysGene() { 
          console.log('testbarplotpred3')
          console.log('test stored', this.$store.state.availableGenePred)
          let res = {}

          this.genes.forEach(gene => {
            let gene_str = gene + '_' + gene
            let gene_pred_str = gene + '_pred_cls'
            let gene_cols = [gene_str, gene_pred_str]
            res[gene] = gene_cols
            
          })
          // console.log(this.exposures)
          console.log('before generate')
          console.log(res)
          console.log(this.trackDataGene)
          // console.log()
          return res
        },
    //     barplotDataType() { return this.$store.state.barplotDataType },
    //     barplotView() { return this.$store.state.barplotView },
        // genes() { 
        //   return this.$store.state.Barplot_gene_genes },
        exposures() { 
          console.log('testpredvue2')
          // return this.$store.state.Barplot_exposures 
          return this.$store.state.BarplotPred_exposures
        },
        
        // trackDataExposureNormalized() {return this.$store.state.Barplot_exposure_data },
        // trackDataExposureRaw() {return this.$store.state.Barplot_exposure_data_raw },
        trackDataExposure() { 
          // return this.useZScore ?
          // this.trackDataExposureNormalized :
          // this.trackDataExposureRaw
          console.log('testpred1')
          return this.$store.state.exposurePred
        },

        genes() { 
          console.log('testpredvue2')
          // return this.$store.state.Barplot_exposures 
          return this.$store.state.BarplotPred_genes
        },
        
        // trackDataExposureNormalized() {return this.$store.state.Barplot_exposure_data },
        // trackDataExposureRaw() {return this.$store.state.Barplot_exposure_data_raw },
        trackDataGene() { 
          // return this.useZScore ?
          // this.trackDataExposureNormalized :
          // this.trackDataExposureRaw
          console.log('testpred1')
          return this.$store.state.genePred
        },
        // trackData() { return this.useZScore ?
        //   this.trackDataNormalized :
        //   this.trackDataRaw
        // },
        
    },
    mounted() {
    },
  }
</script>

<style>
.barplot-pred-container {
    width: 100%;
}
</style>