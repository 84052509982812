<template>
	<!-- <main id="Text"> -->
    <div class="tasklist">
        <div align="center">

  <div style="flex-grow: 1" align="center">
    <img src="../assets/tasklist.png" class="aim-image">
  </div>
  <!-- <div style="flex-grow: 1" align="center">
    <img src="../assets/tasklist2.png" class="aim-image">
  </div> -->
  <a href="https://www.notion.so/9f26240e42814959a3815e2d0cad9898?v=1a848dca2bc84a45b62cfd9a720de33a&pvs=4">
    The tasklist is also available here.
  </a>
  <!-- <div style="flex-grow: 1" align="center">
    <img src="../assets/img/figureB1_gears.jpg" width="350px">
  </div> -->
    
    <!-- <font-awesome-icon :icon="['fas', 'dna']" />
    <font-awesome-icon icon="dna" />
    <i class="fa-solid fa-dna"></i> -->
    <!-- <img src="../assets/img/study_site.jpg" width="280px"> -->
    <!-- <p>
      <img src="../assets/img/sunbeam_goal.png" width="550px">
    </p> -->
  
    
    
  </div>
</div>
		<!-- <h1>More about SunBEAm-ABC</h1>
		<p id="intro"> Our efforts will create a discovery infrastructure for unprecedented systems allergy research during and beyond the SunBEAm-ABC award period that will elucidate mechanisms, biomarkers, and predictors of FA and AD development.
       </p> -->
       
      <!-- <img src="../assets/img/multiomics.png" width="300px"> -->
      
	<!-- </main> -->
</template>

<script>
export default {
    name: "tasklist",
    props: ['view'],
}
</script>

<style scoped>
.content {
  display: flex;

  /* justify-content: space-around; */
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  
}

.content>div{
  flex: 2 1 0;
}

.goal-text {
  display: flex;
  font-size: max(0.9vw, 20px);
  /* font-size: max(1.1vw, 20px); */
/* justify-content: space-around; */
flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
/* align-items: center; */
  padding-left: 3%;
}

.aims-header {
  font-size: max(0.7vw, 15px);
  text-align: left;
  padding-left: 3%;
  padding-bottom: 1vh;
}



.bullets {
  /* display: flex; */
  margin-top: max(2vh, 25px);
  font-size: max(0.9vw, 20px);
  /* align-items: flex-start; */
  /* font-size: max(1.2vw, 20px); */
/* justify-content: space-around;
flex-direction: row;
  flex-wrap: wrap; */
/* align-items: center;
  padding-left: 3%; */
}

.small {
  flex-grow: 1;
  /* border: 3px solid rgba(0, 0, 0, 0.2); */
}

.double {
  flex-grow: 1;
  /* border: 3px solid rgba(0, 0, 0, 0.2); */
}


.aim-image {
  width: 26vw;
  min-width: 700px;
  height: auto;
  /* border: 3px solid rgba(0, 0, 0, 0.2); */
}

.md-tab {
  width: 50%;
  flex: 1 0 30%;
}

</style>


