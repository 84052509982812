<template>
    <div class="input-network-vis">
      <div style="margin-bottom: 10px; padding: 10px; background: #ebebeb">
          Render kinase-substrate interaction network
        </div>
        
  
        <a target="_blank" 
        href="./PhosNetVis/index.html" 
        class="link-container">
          Click here</a> 
        for the network visualization.
      
      <!-- <div>test</div> -->
  
    </div>
  </template>
  
  <script>
//   import InputBoxplotCustomize from './InputBoxplotCustomize.vue'
  
  export default {
      components: {},
      name: "input-network-vis2",
  
      computed: {
        //   availableExposure() { return this.$store.state.availableExposure },
        //   availableGene() { return this.$store.state.availableGene },
          availableNodes() {return this.$store.state.availableKS},
          loaderNetworkVis() { return this.$store.state.loaderNetworkVis },
          nodes() { 
              const available = Object.keys(this.availableNodes)
              available.sort()
              return available
          },
        //   networkNodes() {}
        //   exposures() { 
        //       const available = Object.keys(this.availableExposure)
        //       available.sort()
        //       return available
        //   },
          valid() { 
            // const exposuresValidBool = this.boxplotExposures.length <= this.maxExposures && this.boxplotExposures.length > 0
            // const genesValidBool = this.boxplotGenes.length <= this.maxGenes && this.boxplotGenes.length > 0
            const nodesValidBool = this.networkNodes.length <= this.maxProteins && this.networkNodes.length > 0
            return nodesValidBool 
          },
          tab: {
            get() { return this.$store.state.NetworkVis_tab },
            set(tab) { this.$store.dispatch('setViewTab', { view: 'NetworkVis', tab: tab }) },
          },
          // autocompleteGenes: {
          //   get() { 
          //     return this.boxplotGenes
          //   },
          //   set(autocompleteGenes) { 
          //     this.$store.dispatch(
          //       'getTracks', 
          //       { 
          //         view: 'Boxplot_gene',
          //         genes: autocompleteGenes
          //       }
          //     )
          //   }, 
          // },
          inputNodesText: {
            get() { 
              // return 'abhdkad'
              return this.networkNodes.join('\n') 
            },
            set(inputNodesText) { 
              // this.$store.dispatch(
              //   'getTracks', 
              //   { 
              //     view: 'Boxplot_gene',
              //     genes: inputGenesText
              //       .split('\n')
              //       .filter(gene => ((gene.length > 0) || (this.genes.includes(gene))))
              //       .map(gene => gene.toUpperCase()) 
              //   }
              // )
              this.networkNodes = inputNodesText
                    .split('\n')
                    .filter(gene => ((gene.length > 0) || (this.genes.includes(gene))))
                    .map(gene => gene.toUpperCase())
            }, 
          },
          
      },
      
      data: () => ({
          networkNodes: ['STK11'],
          // renderedBoxplotExposures: ['COBALT-COMPOUNDS', 'ACRYLIC-ACID'],
          loading: false,
          maxProteins: 3,
      }),
  
  
      methods: {
        
  
          submitNodes() {
            this.$store.dispatch('setViewAttr', {
                view: 'NetworkVis',
                attrKey: 'nodes',
                attrVal: this.networkNodes,
            })
            console.log('node in store', this.$store.state.NetworkVis_nodes)
              // console.log('testSubmitBoxplotMultimodal')
            this.$store.dispatch('getSubnetwork', {
              view: 'NetworkVis'
            })
          },
        //   addValueGenes() {
        //     if (this.inputValue && !this.selectedTags.includes(this.inputValue)) {
        //       this.selectedTags.push(this.inputValue);
        //       this.inputValue = "";
        // }
      },
      mounted() { this.submitNodes() }
  }
  </script>
  
  <style>
  .input-boxplot {
      margin: 10px;
  }
  
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .ttest-descriptor {
    width: 100%;
    margin: 10px;
    font-size: 0.8em;
  }
  </style>