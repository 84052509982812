export default function generateBarplot(
    {
        track,
        divId,
        percentView,
        sampleMeta,
        trackDetails,
    }
) {
    const Plotly = window.Plotly

    let data = []

    const ardxAll = getlabel({ 
        sampleMeta, 
    }, 'AR-DX').length
    const nonardxAll = getlabel({ 
        sampleMeta, 
    }, 'Non AR-DX').length

    // const nonCCRCCAll = getnonCCRCC({ 
    //     sampleMeta, 
    // }).length
    const totalAll = ardxAll + nonardxAll

    trackDetails[track].forEach((category) => {
        const categoryLabel = category.label
        const ardx = getCohort_DXLabel({ 
            sampleMeta, 
            track, 
            categoryLabel,
        }, 'AR-DX').length
        const nonardx = getCohort_DXLabel({ 
            sampleMeta, 
            track, 
            categoryLabel,
        }, 'Non AR-DX').length
        
        const total = ardx + nonardx

        let yPercentValue, yPercentValueText, yValue, yValueText, yRawValue, yRawValueText
        // yPercentView = 
        yPercentValue = [
            Math.round(total/totalAll * 100), 
            Math.round(ardx/ardxAll * 100), 
            Math.round(nonardx/nonardxAll * 100)
        ]
        yPercentValueText = yPercentValue.map(el => `${el} %`)
        yRawValue = [
            total,
            ardx,
            nonardx,
        ]
        yRawValueText = yRawValue.map(el => `${el}`)
        // yValueText = yRawValueText.map((value, index) => value.toString() + (yPercentValueText[index]).toString())
        if (percentView) {
            yValue = yPercentValue
            yValueText = yPercentValueText
        } else {
            yValue = yRawValue
            yValueText = yRawValueText
        }
        // TODO: text location
        let trace = {
            x: [`total\n(${totalAll})`, `AR-DX\n(${ardxAll})`, `Non AR-DX\n(${nonardxAll})`],
            y: yValue,
            text: yValueText,
            name: categoryLabel,
            type: 'bar',
            marker: {
                color: category.color
            }
          }

        data.push(trace)
    })
      
    data.reverse()

    var layout = {
        width: 340,
        barmode: 'stack',
        title: track,
        legend: {
            orientation: 'v',
            // traceorder: 'reversed',
        },
        margin: {
            autoexpand: false,
            t: 50,
            r: 150,
        },
        // xaxis:{hoverformat: '.2f'},
        yaxis:{
            title: 'Proportion',
            hoverformat: '.2f'}
    }
      
    Plotly.newPlot(divId, data, layout);
}

function getCohort_DXLabel({ sampleMeta, track, categoryLabel }, dx_label) {
    return Object.entries(sampleMeta)
        .filter(([, meta]) =>  meta.cohort == dx_label && 
            meta[track] === categoryLabel)
        .map(([sample, ]) => sample)
}

// function getCohortnonCCRCC({ sampleMeta, track, categoryLabel }) {
//     return Object.entries(sampleMeta)
//         .filter(([, meta]) =>  meta.tumorClass !== 'ccRCC' 
//             && meta[track] === categoryLabel)
//         .map(([sample, ]) => sample)
// }

// function getCCRCC({ sampleMeta }) {
//     return Object.entries(sampleMeta)
//         .filter(([, meta]) =>  meta.tumorClass === 'ccRCC')
//         .map(([sample, ]) => sample)
// }

// function getnonCCRCC({ sampleMeta }) {
//     return Object.entries(sampleMeta)
//         .filter(([, meta]) =>  meta.tumorClass !== 'ccRCC')
//         .map(([sample, ]) => sample)
// }

function getlabel({ sampleMeta }, label) {
    return Object.entries(sampleMeta)
        .filter(([, meta]) =>  meta.cohort == label)
        .map(([sample, ]) => sample)
}


