<template>
  <!-- <v-app> -->
    <!-- <introduction /> -->
    <div class="testvis">
      <input-container class="input-container-main" />
      <!-- <div class="right-panel"> -->
        <plot-content-page :style="showContentPage" view="Content page" /> 
        <sample-dashboard :style="showSampleDashboard" view="Sample dashboard" /> 
        <heatmap-container :style="showHeatmap" view="Heatmap"  />
        <!-- <boxplot-container-gene :style="showBoxplotGene" view="BoxplotGene" />
        <boxplot-container-exposure :style="showBoxplotExposure" view="BoxplotExposure" /> -->
        <boxplot-container :style="showBoxplot" view="Boxplot" />
        <boxplot-cpg-container :style="showBoxplotCPG" view="BoxplotCPG" />

        <barplot-container :style="showBarplot" view="Barplot" />
        <barplot-pred-container :style="showBarplotPred" view="BarplotPred"/>
        <tasklist-container :style="showTasklist" view="Tasklist" />
        <correlation-container :style="showCorrelation" view="Correlation" />
        <scatterplot-container :style="showScatterplot" view="Scatterplot" />
      <!-- </div> -->
        <!-- <network-vis-container :style="showNetwork" view="Network" /> -->

        <!-- <quality-control-container :style="showQualityControl" view="Quality control" /> -->
        <!-- <histology-viewer-container :style="showHistologyViewer" view="Histology viewer" /> -->
    </div>

  <!-- </v-app> -->
</template>

<script>
// import BoxplotContainer from '../components/BoxplotContainer.vue';
// import BoxplotContainerGene from '../components/BoxplotContainerGene.vue';
// import BoxplotContainerExposure from '../components/BoxplotContainerExposure.vue';
import PlotContentPage from '../components/PlotContentPage.vue';
import NetworkVisContainer from '../components/NetworkVisContainer.vue';

import TasklistContainer from '../components/TasklistContainer.vue';
import BoxplotContainer from '../components/Boxplot/BoxplotContainer.vue';
import BoxplotCpgContainer from '../components/Boxplot/BoxplotCpgContainer.vue';

import BarplotContainer from '../components/Barplot/BarplotExposureContainer.vue';
import BarplotPredContainer from '../components/Barplot/BarplotPredContainer.vue';
import HeatmapContainer from '../components/Heatmap/HeatmapContainer.vue';
import CorrelationContainer from '../components/Correlation/CorrelationMapContainer.vue'
// import HistologyViewerContainer from '../components/HistologyViewerContainer.vue';
import InputContainer from '../components/InputContainer.vue';
//   import Introduction from '../components/Introduction.vue';
import ScatterplotContainer from '../components/Scatterplot/ScatterplotMultiGVE.vue';
// import scatterplotmulti from
import SampleDashboard from '../components/SampleDashboard/SampleDashboard.vue';
// import QualityControlContainer from '../components/QualityControlContainer.vue';

export default {
  name: 'testvis',

  components: {
    // BoxplotContainer,
    // BoxplotContainerGene,
    // BoxplotContainerExposure,
    PlotContentPage,
    BoxplotContainer,
    BarplotContainer,
    HeatmapContainer,
    TasklistContainer,
    BoxplotCpgContainer,
    // HistologyViewerContainer,
    InputContainer,
  //   Introduction,
    SampleDashboard,
    ScatterplotContainer,
    CorrelationContainer,
    BarplotPredContainer,
    NetworkVisContainer
    // QualityControlContainer,
  },

  data: () => ({
  }),

  computed: {
    view() { return this.$store.state.view },
    showContentPage() {return this.view === 'Content page' ? "" : "display: none;" },
    showBoxplot() {return this.view === 'Boxplot' ? "" : "display: none;" },
    showBoxplotCPG() {return this.view === 'BoxplotCPG' ? "" : "display: none;" },

    showBarplot() {return this.view === 'Barplot' ? "" : "display: none;" },
    showTasklist() {return this.view === 'Tasklist' ? "" : "display: none;" },
    // showDemo() { return this.view === 'Demo' ? "" : "display: none;" },
    showHeatmap() { return this.view === 'Heatmap' ? "" : "display: none;" },
    // showIprofun() { return this.view === 'Iprofun' ? "" : "display: none;" },
    showSampleDashboard() { return this.view === 'Sample dashboard' ? "" : "display: none;" },
    showBarplotPred() { 
      // console.log('testpredvis')
      console.log(this.view)
      return this.view === 'BarplotPred' ? "" : "display: none;" },
    showScatterplot() { return this.view === 'Scatterplot' ? "" : "display: none;" },
    showCorrelation() { return this.view === 'Correlation' ? "" : "display: none;" },
    showNetwork() { return this.view === 'Network' ? "" : "display: none;" },

    // showQualityControl() { return this.view === 'Quality control' ? "" : "display: none;" },
    // showHistologyViewer() { return this.view === 'Histology viewer' ? "" : "display: none;" },
  },

  mounted() { 
      // this.$store.dispatch('getTracks', 
      //     { 
      //       view: 'Heatmap', 
      //       genes: ['A1BG']
      //     }
      //   )
  }
};
</script>

<style scoped>
html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

/* #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
} */

.testvis {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

.right-panel {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; */
}
</style>