<template>
  <div class="input-boxplot-customize">
    <!-- <div>test</div> -->
    <!-- <div>
      <v-select
        :items="dataTypes"
        item-value="dataType"
        item-text="text"
        label="Data type:"
        v-model="boxplotDataType"
      >
      </v-select>
    </div> -->
    <div>
      <v-switch
        v-model="useZScore"
        label="Use z-scores"
        color="red darken-3"
        hide-details
      ></v-switch>
    </div>

    <div v-if="!useZScore">
      <v-switch
        v-model="logScale"
        label="log scale"
        color="red darken-2"
        hide-details
      ></v-switch>
    </div>

    <div>
      <v-switch
        v-model="geneCpm"
        label="Gene expression in raw values"
        color="red darken-2"
        hide-details
      ></v-switch>
    </div>

    <div class="stratify-by">
      <v-switch
        v-model="stratify"
        :label="`Stratify by demographics variable`"
      ></v-switch>

      <div class="stratify-by-selector" v-if="stratify">
          <v-autocomplete
            :items="stratifyByOptions"
            label="demographics variable to stratify by:"
            v-model="stratifyBy"
          >
        </v-autocomplete>
      </div>
    </div>

    
  </div>
</template>

<script>
export default {
    name: "input-boxplot-customize",

    computed: {
      boxplotDataType: {
        get() { return this.$store.state.Boxplot_dataType },
        set(Boxplot_dataType) { this.$store.dispatch(
          'setBoxplotDataType', 
          { view: 'Boxplot', Boxplot_dataType }
        )}
      },
      // categoryTracks() { return this.$store.state.Heatmap_categoryTracks },
      categoryTracks() { 
        return ['Age', 'Sex', 'Race_ethnicity']
      },
      stratifyBy: {
        get() { return this.$store.state.Boxplot_stratifyBy },
        set(Boxplot_stratifyBy) { 
          this.$store.dispatch('setBoxplotStratifyBy', { 
            view: 'Boxplot',
         Boxplot_stratifyBy }) 
        }
      },
      stratifyByOptions() {  
        console.log('this cat tracks', this.categoryTracks)
        return this.categoryTracks
        // return Object.keys(sampleMeta)
        // return Object.values(this.categoryTracks).flat().filter(track => !this.categoryTracks.Numerical.includes(track))
      },
      useZScore: {
        get() { return this.$store.state.Boxplot_useZScore },
        set(v) { 
          this.$store.dispatch(
            'setUseZScore',
            { 
              view: 'Boxplot',
              useZScore: v 
            }
          )
        },
      },
      stratify: {
        get() { return this.$store.state.Boxplot_stratify },
        set(v) { 
          this.$store.dispatch('setStratify',{ 
              view: 'Boxplot',
              stratify: v 
            })
        }
      },
      logScale: {
        get() { return this.$store.state.Boxplot_logScale },
        set(v) { 
          this.$store.dispatch(
            'setLogScale',
            { 
              view: 'Boxplot',
              logScale: v 
            }
          )
        }
      },
      geneCpm: {
        get() { return this.$store.state.Boxplot_geneCpm },
        set(v) { 
          this.$store.dispatch(
            'setGeneCpm',
            { 
              view: 'Boxplot',
              geneCpm: v 
            }
          )
        }
      },
      
    },
    
    data: () => ({
      dataTypes: ['All', ''],
      // stratify: false,
      // geneCpm: false,
    }),

    methods: {
    },
}
</script>

<style>
.input-boxplot-customize {
    margin: 10px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>