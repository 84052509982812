<template>
  <!-- <div class="scatterplot">
    <div v-if="tracks.length === 0" style="width: 70%; margin: 10px; background: #ebebeb; padding: 20px;">
      Select genes and data types on the left to generate a scatterplot.
    </div>
    <div :id="divId">
    </div>
  </div> -->
  <div class="scatterplot">
  <div v-if="trackData">
      <!-- <div>test</div> -->
      <scatterplotsingle
        v-for="(trackPair, scatterplotIdx) in trackData"
        :key="scatterplotIdx"
        :dataKeys="scatterplotIdx"
        :trackPair="trackPair"
        :useZScore="useZScore"
      />
    </div>
  </div>
</template>

<script>
import Scatterplotsingle from './ScatterplotSingleGVE.vue'

export default {
    name: "scatterplot",
    components: { Scatterplotsingle },
    // props: ['view'],
    data: () => ({
    }),
    
    computed: {
      divId() { return 'test-scatter' },

      useZScore() { return this.$store.state.Scatterplot_useZScore },
      trackDataNormalized() { return this.$store.state.Scatterplot_data },
      trackDataRaw() { return this.$store.state.Scatterplot_data_raw },
      trackData() { 
        console.log('test scattter multi 1')
        console.log(this.trackDataRaw)
        return this.useZScore ? 
        this.trackDataNormalized 
        : this.trackDataRaw 
      },
    },

    watch: {
      
    },

    methods: {

    },

    mounted() {

    },
  }
</script>

<style>
.scatterplotmulti {
    width: 100%;
}
</style>