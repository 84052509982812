<template>
	<main id="Text">
        <div align="center">
		<!-- <h1>Resources</h1> -->
        <br><br>
        <div class="bullets">
            <!-- In this section of the portal, we will soon share the following resources from SunBEAm-ABC that should help advance the study of food allergy (FA) and atopic dermatitis (AD): -->
            Please send any suggestions or problems related to this portal to 
            <a target="_blank" href="mailto:sunbeam.abc.web@gmail.com" class="link-container">Yan Chak Li</a>.
    <!-- <br><br>
    <ul>
      
     <li>Links to  <a target="_blank" href="https://www.immport.org/shared/home" class="link-container">ImmPort</a> location of data generated by SumBEAm-ABC. <br><br>
     </li><li>Data visualization and analysis tools developed by SunBEAm-ABC<br><br> -->
     <!-- </li><li>Novel systems biology and machine learning software to integrate multi-omics and clinical data that will reveal novel biomarkers and mechanisms of FA and AD.<br><br> -->
     <!-- </li> -->
     <!-- <li>Share all generated data in a centralized platform for SunBEAm data and bioinformatic innovations.<br><br><br> -->
     <!-- </li><li>Develop infrastructure for interactions with the SunBEAm Steering Group, the NIAID Statistical and Clinical Coordinating Center, and NIAID. <br><br> -->
      <!-- </li> -->
    <!-- </ul> -->
  </div>
        <!-- <img src="../assets/img/coming-soon.gif" class="coming-soon"> -->
		<!-- <p id="intro"> Under development...</p> -->
       <!-- <img src="../assets/img/primary_endpoint.png" width="300px">
      <img src="../assets/img/multiomics.png" width="300px">
      <img src="../assets/img/study_site.jpg" width="300px"> -->
    </div>
	</main>
</template>

<script>
export default {
    name: "contact",
}
</script>

<style scoped>
.bullets {
  /* display: flex; */
  /* font-size: max(1.2vw, 25px); */
  /* font-size: max(1.1vw, 20px); */
  font-size: max(0.9vw, 20px);
/* justify-content: space-around;
flex-direction: row;
  flex-wrap: wrap; */
  /* text-align: left; */
/* align-items: center; */
  padding-left: 3%;
  padding-right: 3%;
}

.coming-soon {
    min-width: 300px;
    width: 20vw;

}

.link-container {
  color: #219EBC;

}
</style>

