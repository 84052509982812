<template>
  <div class="boxplot-cpg-container">

      <!-- <boxplot-cpg
        v-for="(track, dataKey) in k_cpg_exposure_pairs"
        :plotData="track"
        :divId="dataKey"
        v-bind:key="dataKey"
      /> -->
    
      <boxplot-cpg
        v-for="k in Object.keys(k_cpg_exposure_pairs).sort()"
        :plotData="k_cpg_exposure_pairs[k]"
        :divId="k"
        v-bind:key="k"
      />
  </div>
</template>

<script>
import BoxplotCpg from './BoxplotCpg.vue'

export default {
    components: { BoxplotCpg },
    name: "boxplot-cpg-container",

    props: ['view'],

    data: () => ({
    }),
    
    computed: {
        cpg_exposure_pairs() {return this.$store.state.BoxplotCPG_cpgData},
        k_cpg_exposure_pairs() { 
          // console.log('boxplot cpg container', this.$store.state.BoxplotCPG_cpgData)
          // onsole.log('boxplot cpgs container', this.$store.state.BoxplotCPG_cpg)
          // let x = 1;
          // return this.$store.state.BoxplotCPG_cpgData
          let res = {}
          
          this.cpg_exposure_pairs.forEach(e => {
            let k = e['CpGName']+ '-' + e['AirToxicName'] + '-boxplot'
            res[k] = e
          });
          return res
          // return this.$store.state.BoxplotCPG_cpgData
        },
        cpgs() {  return this.$store.state.BoxplotCPG_cpg},
    
    },
    mounted() {
    },
  }
</script>

<style>
.boxplot-cpg-container {
    width: 100%;
}
</style>