<template>
  <div class="scatterplotsingle">
    <!-- test -->
    <div v-if="tracks.length === 0" style="width: 70%; margin: 10px; background: #ebebeb; padding: 20px;">
      Select genes and data types on the left to generate a scatterplot.
    </div>
    <div :id="divId">
    </div>
  </div>
</template>

<script>
import scatterplot from '../../plotly/scatterplotlygve'

export default {
    name: "scatterplotsingle",
    props: ['dataKeys', 'trackPair', 'useZScore'],
    data: () => ({
    }),
    
    computed: {
      divId() { return 'test-scatter'+this.dataKeys},
      tracks() {
        return this.trackKeys
      },
      trackKeys() { return Object.keys(this.trackData).slice(-6)},
      trackADT() { return this.$store.state.Scatterplot_dataX_DT},
      trackBDT() { return this.$store.state.Scatterplot_dataY_DT},
      // useZScore() { return this.$store.state.Scatterplot_useZScore },
      // trackDataNormalized() { return this.$store.state.Scatterplot_data },
      // trackDataRaw() { return this.$store.state.Scatterplot_data_raw },
      // trackData() { return this.useZScore ? 
      //   this.trackDataNormalized 
      //   : this.trackDataRaw 
      // },
      trackData() {
        console.log('testscatter 8')
        console.log(this.dataKeys)
        return this.trackPair
      }
    },

    watch: {
      tracks() { this.renderScatterplot() },
      trackData() { this.renderScatterplot() },
    },

    methods: {
      renderScatterplot() {
        scatterplot({
          divId: this.divId,
          trackData: this.trackData,
          trackKeys: this.trackKeys,
          useZScore: this.useZScore,
          trackADT: this.trackADT,
          trackBDT: this.trackBDT,
        })
      },
    },

    mounted() {

    },
  }
</script>

<style>
.scatterplotsingle {
    width: 100%;
}
</style>