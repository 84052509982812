<template>
  <div class="heatmap-container">
    <div>
      <draggable tag="v-layout" v-model="draggableCards"
       class="heatmap-container-heatmaps"
      >
        <!-- <template  > -->
          <v-card v-for="trackGroup in trackGroups" v-bind:key="trackGroup" elevation="0"  class="mx-auto">
            <div :id="generateDivId(trackGroup)">
            </div>
          <br>
          </v-card>
        <!-- </template> -->
      </draggable>
    </div>
    <heatmap-legend />
  </div>
</template>

<script>
import heatmap from '../../plotly/heatmap'
import HeatmapLegend from './HeatmapLegend.vue'
import draggable from 'vuedraggable'

export default {
    components: { 
      draggable, 
      HeatmapLegend 
    },
    name: "heatmap-container",

    props: ['view'],

    data() {
      return {
      }
    },

    computed: {
      categoryTracks() { return this.$store.state.Heatmap_categoryTracksFiltered },
      trackGroups() { 
        console.log('see trackgroups', this.$store.state.Heatmap_trackGroups)
        return this.$store.state.Heatmap_trackGroups 
        // return ['Ungrouped']
      },
      draggableCards: {
        get() {
          return this.trackGroups
        },
        set(Heatmap_trackGroups) {
          this.$store.dispatch(
                'setHeatmapTrackGroups', 
                { Heatmap_trackGroups }
              )
        }
      },
      genes() { 
        console.log('see genes', this.$store.state.Heatmap_genes)
        return this.$store.state.Heatmap_genes }, 
      exposures() { return this.$store.state.Heatmap_exposures }, 
      shownDataTypes() { return this.$store.state.Heatmap_shownDataTypes },
      trackData() {
        console.log('trackDataHeatmap', this.$store.state.Heatmap_data)
        return this.$store.state.Heatmap_data 
      }, 
      // trackGeneData() {
      //   console.log('trackDataHeatmap Gene') 
      //   console.log(this.$store.state.Heatmap_gene_data)
      //   return this.$store.state.Heatmap_gene_data 
      // }, 
      // trackExposureData() {
      //   console.log('trackDataHeatmap Exposure') 
      //   console.log(this.$store.state.Heatmap_exposure_data)
      //   return this.$store.state.Heatmap_exposure_data 
      // }, 
      sampleOrder() { return this.$store.state.Heatmap_sampleOrder }, 
    },

    watch: {
      categoryTracks() { this.renderHeatmap() },
      trackData() { this.renderHeatmap() },
      // trackGeneData() { this.renderHeatmap() },
      // trackExposureData() { this.renderHeatmap() },
      sampleOrder() { this.renderHeatmap() },
      shownDataTypes() { this.renderHeatmap() },
    },

    methods: {
      generateDivId(trackGroup) { 
        // console.log('trackgroups')
        // console.log(trackGroup)
        return `plotly-heatmap-${trackGroup}` },
      renderHeatmap() {
        this.trackGroups.forEach((trackGroup) => {
          console.log('trackgroups', trackGroup)
          // let trackData_ge = {'Genes': this.trackGeneData, 
          // 'Air toxics': this.trackExposureData
        // }
          let plot = heatmap({
            categoryTracks: this.categoryTracks,
            trackGroup: trackGroup,
            genes: this.genes,
            exposures: this.exposures,
            trackData: this.trackData,
            sampleOrder: this.sampleOrder,
            shownDataTypes: this.shownDataTypes,
          })
  
          let that = this
          console.log('before heatmap_hover')
          console.log(trackGroup)
          console.log(plot)
          if (plot) {
            plot.on('plotly_hover', function(data) {
  
              const sample = data.points[0].x
              const track = data.points[0].y
              // print()
              if (track in that.trackData) {
                const value = that.trackData[track].data[sample]
                // Hovertext
                if (typeof value === "string") {
                data.points[0].fullData.hovertemplate = 
                  `${sample}<br>${track}: ${value}<br><extra></extra>`
                // if (value === null) {
                //   console.log('hover is null', )
                // } else {
                //   console.log('hover', value)
                // }
                } else {
                  data.points[0].fullData.hovertemplate = 
                  `${sample}<br>${track}(Z-score): ${value.toFixed(3)}<br><extra></extra>`
                }
              } else {
                // data.points[0].fullData.hovertemplate = 
                //   `<extra></extra>`
              }
            })
          }
        })
      }
    },
}
</script>

<style>
.heatmap-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    margin-top: 20px;
}

.heatmap-container-heatmaps {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #92a8d1;
    margin-top: 0px;
    margin-bottom: 0px;
}
</style>