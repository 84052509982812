<template>
  <div class="input-boxplot">
    <div style="margin-bottom: 10px; padding: 10px; background: #ebebeb">
        Render boxplots of gene expression and air toxics abundance stratified by AR-DX and non AR-DX. <br><br>
        * The p-values of lines are calculated based on student t-test.
      </div>
      <v-app>
      <v-card flat>
      <v-tabs
        v-model="tab"
      >
          <v-tab href="#tab-1">
            Select
          </v-tab>

          <v-tab href="#tab-2">
            Enter list
          </v-tab>

          <!-- <v-tab href="#tab-3">
            Download
          </v-tab> -->
        </v-tabs>

        <v-tabs-items v-model="tab">

          <v-card style="margin-top: 20px;">

            <v-tab-item value="tab-1">
            <div>
              <v-autocomplete
        v-model="boxplotGenes"
        :items="genes"
        outlined
        chips
        deletable-chips
        small-chips
        :menu-props="{closeOnContentClick: true}"
        dense
        :label="`Select up to ${maxGenes} genes`"
        multiple
        clearable
    >
    </v-autocomplete>
    <v-autocomplete
        v-model="boxplotExposures"
        :items="exposures"
        outlined
        chips
        deletable-chips
        small-chips
        :menu-props="{closeOnContentClick: true}"
        dense
        :label="`Select up to ${maxExposures} air toxics`"
        multiple
        clearable
    >
    </v-autocomplete>
    <div
      @click="submitBoxplot"
    >
    <v-btn
        v-if="boxplotExposures.length > 0 || boxplotGenes.length > 0"
        :loading="loaderBoxplot"
        :disabled="loading || !valid"
        color="blue-grey"
        class="ma-2 white--text"
      >
        
        {{ valid ? 'Render boxplots' : 'Select fewer genes/air toxics' }}
    </v-btn>
    </div>
    <input-boxplot-customize />
  </div>
            </v-tab-item>

            <v-tab-item value="tab-2">
              <v-textarea
        v-model="inputGenesText"
        outlined
        name="input-7-4"
        label="Enter list of genes separated by newline"
        height="120"
        no-resize
      ></v-textarea>

      <v-textarea
        v-model="inputExposuresText"
        outlined
        name="input-7-4"
        label="Enter list of air toxics separated by newline"
        height="120"
        no-resize
      ></v-textarea>
      <div
      @click="submitBoxplot"
    >
      <v-btn
        v-if="boxplotExposures.length > 0 || boxplotGenes.length > 0"
        :loading="loaderBoxplot"
        :disabled="loading || !valid"
        color="blue-grey"
        class="ma-2 white--text"
      >
        
        {{ valid ? 'Render boxplots' : 'Select fewer genes/air toxics' }}
    </v-btn>
    </div>
    <input-boxplot-customize />
            </v-tab-item>

            <!-- <v-tab-item value="tab-3">
              3
            </v-tab-item> -->

          </v-card>

        </v-tabs-items>

      </v-card>
    </v-app> 


    
    <!-- <div>test</div> -->

  </div>
</template>

<script>
import InputBoxplotCustomize from './InputBoxplotCustomize.vue'

export default {
    components: { InputBoxplotCustomize },
    name: "input-boxplot",

    computed: {
        availableExposure() { return this.$store.state.availableExposure },
        availableGene() { return this.$store.state.availableGene },
        loaderBoxplot() { return this.$store.state.loaderBoxplot },
        genes() { 
            const available = Object.keys(this.availableGene)
            available.sort()
            return available
        },
        exposures() { 
            const available = Object.keys(this.availableExposure)
            available.sort()
            return available
        },
        valid() { 
          const exposuresValidBool = this.boxplotExposures.length <= this.maxExposures && this.boxplotExposures.length > 0
          const genesValidBool = this.boxplotGenes.length <= this.maxGenes && this.boxplotGenes.length > 0
          return  exposuresValidBool || genesValidBool 
        },
        tab: {
          get() { return this.$store.state.Boxplot_tab },
          set(tab) { this.$store.dispatch('setViewTab', { view: 'Boxplot', tab: tab }) },
        },
        // autocompleteGenes: {
        //   get() { 
        //     return this.boxplotGenes
        //   },
        //   set(autocompleteGenes) { 
        //     this.$store.dispatch(
        //       'getTracks', 
        //       { 
        //         view: 'Boxplot_gene',
        //         genes: autocompleteGenes
        //       }
        //     )
        //   }, 
        // },
        inputGenesText: {
          get() { 
            // return 'abhdkad'
            return this.boxplotGenes.join('\n') 
          },
          set(inputGenesText) { 
            // this.$store.dispatch(
            //   'getTracks', 
            //   { 
            //     view: 'Boxplot_gene',
            //     genes: inputGenesText
            //       .split('\n')
            //       .filter(gene => ((gene.length > 0) || (this.genes.includes(gene))))
            //       .map(gene => gene.toUpperCase()) 
            //   }
            // )
            this.boxplotGenes = inputGenesText
                  .split('\n')
                  .filter(gene => ((gene.length > 0) || (this.genes.includes(gene))))
                  .map(gene => gene.toUpperCase())
          }, 
        },
        inputExposuresText: {
          get() { 
            // return 'abhdkad'
            return this.boxplotExposures.join('\n') 
          },
          set(inputExposuresText) { 
            // this.$store.dispatch(
            //   'getTracksExposure', 
            //   { 
            //     view: 'Boxplot_exposure',
            //     exposures: inputExposuresText
            //       .split('\n')
            //       .filter(exposure => ((exposure.length > 0) || (this.exposures.includes(exposure))))
            //       .map(exposure => exposure.toUpperCase()) 
            //   }
            // )
            this.boxplotExposures = inputExposuresText
                  .split('\n')
                  .filter(exposure => ((exposure.length > 0) || (this.exposures.includes(exposure))))
                  .map(exposure => exposure.toUpperCase()) 
          }, 
        },
    },
    
    data: () => ({
        boxplotExposures: ['COBALT-COMPOUNDS', 'ACRYLIC-ACID'],
        // renderedBoxplotExposures: ['COBALT-COMPOUNDS', 'ACRYLIC-ACID'],
        loading: false,
        maxExposures: 10,
        boxplotGenes: ['PRB1', 'PRTG'],
        // renderedboxplotGenes: ['PRB1', 'PRTG'],
        maxGenes: 10,
    }),


    methods: {
      

        submitBoxplot() {
            // console.log('testSubmitBoxplotMultimodal')
            this.$store.dispatch('getTracksExposure', 
            { 
              view: 'Boxplot_exposure', 
              exposures: this.boxplotExposures 
            }
          )
          // console.log(this.boxplotExposures)
          if (this.$store.state.Boxplot_geneCpm) {
            this.$store.dispatch('getTracksCpm', 
              { 
                view: 'Boxplot_gene', 
                genes: this.boxplotGenes 
              }
            )
          } else {
            this.$store.dispatch('getTracks', 
              { 
                view: 'Boxplot_gene', 
                genes: this.boxplotGenes 
              }
            )
          }
          // console.log('testboxplot1')
          // console.log(this.$store.state.Boxplot_genes)
          // console.log(this.$store.state.Boxplot_exposures)
          // console.log(this.$store.state.Boxplot_gene_data)
          // console.log('testSubmitGenes2')
        },
      //   addValueGenes() {
      //     if (this.inputValue && !this.selectedTags.includes(this.inputValue)) {
      //       this.selectedTags.push(this.inputValue);
      //       this.inputValue = "";
      // }
    },
    mounted() { this.submitBoxplot() }
}
</script>

<style>
.input-boxplot {
    margin: 10px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.ttest-descriptor {
  width: 100%;
  margin: 10px;
  font-size: 0.8em;
}
</style>