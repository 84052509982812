<template>
  <div class="input-boxplot-cpg">
    <div style="margin-bottom: 10px; padding: 10px; background: #ebebeb">
        Render boxplots of air toxic abundance vs adjusted DNA methylation expression
      </div>

            <div>
              <v-autocomplete
        v-model="boxplotCPG"
        :items="cpgs"
        outlined
        chips
        deletable-chips
        small-chips
        :menu-props="{closeOnContentClick: true}"
        dense
        :label="`Select up to ${maxCPGs} chromsome(s)`"
        multiple
        clearable
    >
    </v-autocomplete>
  </div>


    <div
      @click="submitBoxplot"
    >
      <v-btn
        v-if="boxplotCPG.length > 0"
        :loading="loaderBoxplot"
        :disabled="loading || !valid"
        color="blue-grey"
        class="ma-2 white--text"
      >
        
        {{ valid ? 'Render boxplots' : 'Select fewer chromosome' }}
    </v-btn>
    </div> 
    
    <!-- <div>test</div> -->

  </div>
</template>

<script>
// import InputBoxplotCustomize from './InputBoxplotCustomize.vue'

export default {
    components: { },
    name: "input-boxplot-cpg",

    computed: {
        availableCPG() { return this.$store.state.availableCPG },
        // availableGene() { return this.$store.state.availableGene },
        loaderBoxplot() { return this.$store.state.loaderBoxplot },
        cpgs() { 
            const available = Object.keys(this.availableCPG)
            available.sort()
            return available
        },
        valid() { 
          // const exposuresValidBool = this.boxplotExposures.length <= this.maxExposures && this.boxplotExposures.length > 0
          const genesValidBool = this.boxplotCPG.length <= this.maxCPGs && this.boxplotCPG.length > 0
          return  genesValidBool 
        },
        
    },
    
    data: () => ({
        boxplotCPG: ['cg27143824'],
        loading: false,
        maxCPGs: 10,
    }),


    methods: {
      

        submitBoxplot() {
            this.$store.dispatch('getCPGTracks', 
            { 
              view: 'BoxplotCPG', 
              cpgs: this.boxplotCPG 
            }
          )
          console.log('box cpg data', this.$store.state.BoxplotCPG_cpgData)
        },
      //   addValueGenes() {
      //     if (this.inputValue && !this.selectedTags.includes(this.inputValue)) {
      //       this.selectedTags.push(this.inputValue);
      //       this.inputValue = "";
      // }
    },
    mounted() { this.submitBoxplot() }
}
</script>

<style>
.input-boxplot {
    margin: 10px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.ttest-descriptor {
  width: 100%;
  margin: 10px;
  font-size: 0.8em;
}
</style>