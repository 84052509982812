
function getCohort_DXLabel({ sampleMeta, track, categoryLabel }, dx_label) {
    return Object.entries(sampleMeta)
        .filter(([, meta]) =>  meta.cohort == dx_label && 
            meta[track] === categoryLabel)
        .map(([sample, ]) => sample)
}

// function getCohortnonCCRCC({ sampleMeta, track, categoryLabel }) {
//     return Object.entries(sampleMeta)
//         .filter(([, meta]) =>  meta.tumorClass !== 'ccRCC' 
//             && meta[track] === categoryLabel)
//         .map(([sample, ]) => sample)
// }

// function getCCRCC({ sampleMeta }) {
//     return Object.entries(sampleMeta)
//         .filter(([, meta]) =>  meta.tumorClass === 'ccRCC')
//         .map(([sample, ]) => sample)
// }

// function getnonCCRCC({ sampleMeta }) {
//     return Object.entries(sampleMeta)
//         .filter(([, meta]) =>  meta.tumorClass !== 'ccRCC')
//         .map(([sample, ]) => sample)
// }

function getlabel({ sampleMeta }, label) {
    return Object.entries(sampleMeta)
        .filter(([, meta]) =>  meta.cohort == label)
        .map(([sample, ]) => sample)
}

// function getCohort_DXLabel({ sampleMeta, track, categoryLabel }, dx_label) {
//     return Object.entries(sampleMeta)
//         .filter(([, meta]) =>  meta.cohort == dx_label && 
//             meta[track] === categoryLabel)
//         .map(([sample, ]) => sample)
// }


// TODO: make barplot group with x-axis by quantile
export default function generateBarplotExposure(
    {
        track,
        divId,
        percentView,
        sampleMeta,
        trackData,
        dataKeys,
    }
) {
    console.log('barplotlyExposure1')
    const Plotly = window.Plotly

    // let data = []

    const ardxAll = getlabel({ 
        sampleMeta, 
    }, 'AR-DX').length
    const nonardxAll = getlabel({ 
        sampleMeta, 
    }, 'Non AR-DX').length
    console.log(ardxAll)
    console.log(track)
    console.log(trackData)
    console.log(dataKeys)
    console.log(sampleMeta)
    let posDataKey = track + ' T'
    let negDataKey = track + ' N'
    
    let quantile_groups = [1, 2, 3, 4]

    // const nonCCRCCAll = getnonCCRCC({ 
    //     sampleMeta, 
    // }).length
    const totalAll = ardxAll + nonardxAll
    let data = []
    let posDict = trackData[posDataKey].data
    let posValues = Object.keys(posDict).map(function(key){
        return posDict[key];
    });
    let negDict = trackData[negDataKey].data
    let negValues = Object.keys(negDict).map(function(key){
        return negDict[key];
    });
    console.log(posValues, negValues)
    console.log(posDict, negDict)

    let yPercentValue, yPercentValueText, yValue, yValueText, yRawValue, yRawValueText
        // yPercentView = 
    let yPercentValuePos = []
    let yPercentValueNeg = []
    let yCountPos = []
    let yCountNeg = []
    for (let q = 1; q < 5; q++){
        // console.log(trackData[posDataKey].data)
        
        
        // console.log(posValues)
        let posCount = posValues.filter(x => x==q).length
        let negCount = negValues.filter(x => x==q).length
        let totalCount = posCount + negCount
        let yPercentPos = posCount/totalCount 
        let yPercentNeg = negCount/totalCount
        
        yPercentValuePos.push(yPercentPos.toFixed(2))
        yPercentValueNeg.push(yPercentNeg.toFixed(2))
        yCountPos.push(posCount)
        yCountNeg.push(negCount)

    }
    let tracePos = {
                x: ['1st', '2nd', 
                '3rd', '4th'],
                y: yPercentValuePos,
                text: yPercentValuePos,
                name: 'AR-DX',
                type: 'bar',
                marker: {
                    color: '#DC3220'
                }
              }
    
    let traceNeg = {
                x: ['1st', '2nd', 
                '3rd', '4th'],
                y: yPercentValueNeg,
                text: yPercentValueNeg,
                name: 'Non AR-DX',
                type: 'bar',
                marker: {
                    color: '#005AB5'
                }
              }

    var layout = {
        width: 500,
        xaxis: {
            title: {
              text: 'Quartile',
            },
          },
          yaxis: {
            title: {
              text: 'Proportion',
            },
          },
        barmode: 'stack',
        title: track,
        legend: {
            orientation: 'v',
            // traceorder: 'reversed',
        },
        margin: {
            // autoexpand: false,
            t: 50,
            r: 150,
        }
    }

    Plotly.newPlot(divId, 
        [tracePos, traceNeg], 
        layout);
    
    // trackData[track].forEach((category) => {
    //     const categoryLabel = category.label
    //     const ardx = getCohort_DXLabel({ 
    //         sampleMeta, 
    //         track, 
    //         categoryLabel,
    //     }, 'AR-DX').length
    //     const nonardx = getCohort_DXLabel({ 
    //         sampleMeta, 
    //         track, 
    //         categoryLabel,
    //     }, 'Non AR-DX').length
        
    //     const total = ardx + nonardx

    //     let yPercentValue, yPercentValueText, yValue, yValueText, yRawValue, yRawValueText
    //     // yPercentView = 
    //     yPercentValue = [
    //         Math.round(total/totalAll * 100), 
    //         Math.round(ardx/ardxAll * 100), 
    //         Math.round(nonardx/nonardxAll * 100)
    //     ]
    //     yPercentValueText = yPercentValue.map(el => `${el} %`)
    //     yRawValue = [
    //         total,
    //         ardx,
    //         nonardx,
    //     ]
    //     yRawValueText = yRawValue.map(el => `${el}`)
    //     // yValueText = yRawValueText.map((value, index) => value.toString() + (yPercentValueText[index]).toString())
    //     if (percentView) {
    //         yValue = yPercentValue
    //         yValueText = yPercentValueText
    //     } else {
    //         yValue = yRawValue
    //         yValueText = yRawValueText
    //     }
    //     // TODO: text location
    //     let trace = {
    //         x: [`total\n(${totalAll})`, `AR-DX\n(${ardxAll})`, `Non AR-DX\n(${nonardxAll})`],
    //         y: yValue,
    //         text: yValueText,
    //         name: categoryLabel,
    //         type: 'bar',
    //         marker: {
    //             color: category.color
    //         }
    //       }

    //     data.push(trace)
    // })
      
    // data.reverse()

    // var layout = {
    //     width: 340,
    //     barmode: 'stack',
    //     title: track,
    //     legend: {
    //         orientation: 'v',
    //         // traceorder: 'reversed',
    //     },
    //     margin: {
    //         autoexpand: false,
    //         t: 50,
    //         r: 150,
    //     }
    // }
      
    // Plotly.newPlot(divId, data, layout);
}




// function getnonCCRCC({ sampleMeta }) {
//     return Object.entries(sampleMeta)
//         .filter(([, meta]) =>  meta.tumorClass !== 'ccRCC')
//         .map(([sample, ]) => sample)
// }