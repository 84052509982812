<template>
  <div class="input-heatmap-selector">
    <v-autocomplete
        v-model="heatmapGeneInput"
        :items="genes"
        outlined
        chips
        deletable-chips
        small-chips
        :menu-props="{closeOnContentClick: true}"
        dense
        :label="`Select up to ${maxGenes} genes`"
        multiple
        clearable
    >
    </v-autocomplete>

    <v-autocomplete
        v-model="heatmapExposureInput"
        :items="exposures"
        outlined
        chips
        deletable-chips
        small-chips
        :menu-props="{closeOnContentClick: true}"
        dense
        :label="`Select up to ${maxExposures} air toxics`"
        multiple
        clearable
    >
    </v-autocomplete>

    <v-btn
        v-if="heatmapGeneInput.length > 0 || heatmapExposureInput.length > 0"
        :loading="loaderHeatmap"
        :disabled="loading || !valid"
        color="blue-grey"
        class="ma-2 white--text"
        @click="submitAndMove"
      >
        {{ valid ? 'Render heatmap' : 'Select fewer genes/air toxics' }}
    </v-btn>
  </div>
</template>

<script>

export default {
    name: "input-heatmap-selector",

    computed: {
        availableGene() { return this.$store.state.availableGene },
        availableExposure() { return this.$store.state.availableExposure },
        
        categorical() { return Object.keys(this.$store.state.Heatmap_categoryTracks) },
        // notFound() {
        //   return this.heatmapGeneInput
        //     .split('\n')
        //     .map(gene => gene.toUpperCase())
        //     .filter(gene => gene.length > 0 && !this.genes.includes(gene) )
        // },
        genes() { 
          const available = Object.keys(this.availableGene)
          available.sort()
          return available
        },
        exposures() { 
          const available = Object.keys(this.availableExposure)
          available.sort()
          return available
        },
        loaderHeatmap() { return this.$store.state.loaderHeatmap },
        valid() { 
          const genesValidBool = this.heatmapGeneInput.length <= this.maxGenes && this.heatmapGeneInput.length > 0
          const exposuresValidBool = this.heatmapExposureInput.length <= this.maxExposures && this.heatmapExposureInput.length > 0
          return (exposuresValidBool) || (genesValidBool)},
    },
    
    data: () => ({
      // heatmapGeneInput: '',
      heatmapGeneInput: '',
      heatmapExposureInput: '',
      maxGenes: 10,
      maxExposures: 10,
      loading: false,
    }),


    methods: {
      clearInput() { this.heatmapGeneInput = '' },
      submitHeatmapGenes() {

          this.$store.dispatch('getTrackHeatmapData',
              {
                view: 'Heatmap', 
                genes: this.heatmapGeneInput,
                exposures: this.heatmapExposureInput,
              }
          )
          console.log('setHeatmapTrackGroups')
          this.$store.dispatch(
            'setHeatmapTrackGroups', 
            { Heatmap_trackGroups: [...this.categorical, 'Genes', 'Air toxics']}
            // { Heatmap_trackGroups: [...this.categorical, ...this.heatmapGeneInput, ...this.heatmapExposureInput]}
          )
      },
      submitAndMove() {
        this.submitHeatmapGenes()
        this.$store.dispatch('setHeatmapTab', { Heatmap_tab: 'tab-2' })
      },
    },

    mounted() { 
      
      // this.submitHeatmapGenes() 
      this.heatmapGeneInput = ['PRB1', 'PRTG']
      this.heatmapExposureInput = ['COBALT-COMPOUNDS', 'ACRYLIC-ACID']
      
      const categorical = Object.keys(this.$store.state.Heatmap_categoryTracks)
      this.$store.dispatch(
        'setHeatmapTrackGroups', 
        { Heatmap_trackGroups: [...categorical, 'Genes', 'Air toxics']}
        // { Heatmap_trackGroups: [...categorical] }
      )
      // this.submitHeatmapGenes()
    },
}
</script>

<style>
.input-heatmap {
    margin: 10px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>