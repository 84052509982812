<template>
    <div class="boxplot">
        <!-- test -->
        <div :id="divId">
        </div>
    </div>
    <!-- <div class="boxplot">
        <div :id="divId">
        </div>
    </div> -->
  </template>
  
  <script>
  import boxplot from '../../plotly/boxplotly'
  
  export default {
      name: "boxplot",
  
      props: [ 'track', 'dataKeys', 'trackData', 
      'useZScore', 'dataType', 'logScale', 'geneCpm'],
      
      data: () => ({
          view: 'Boxplot',
      }),
  
      computed: {
          stratifyBy() { 
              console.log('st_by')
              return this.$store.state.Boxplot_stratifyBy },
          divId() { 
            //   console.log('usds'.split(' ').join('-'))
              return `${this.track.split(' ').join('-')}-${this.view}` },
          sampleMeta() { return this.$store.state.sampleMeta },
          
        //   dataType() {return 'exposure'},
      },
  
      watch: {
          stratifyBy() { this.generateBoxplot() },
          track() { this.generateBoxplot() },
          trackData() { this.generateBoxplot() },
          dataKeys() { this.generateBoxplot() },
          logScale() { this.generateBoxplot() },
      },
  
      methods: {
          generateBoxplot() {
              console.log('gen boxplot') 
              if (!this.dataKeys) { return }
              boxplot({
                  divId: this.divId,
                  trackData: this.trackData,
                  dataKeys: this.dataKeys,
                  track: this.track,
                  stratifyBy: this.stratifyBy,
                  useZScore: this.useZScore,
                  logScale: this.logScale,
                  dataType: this.dataType,
                  geneCpm: this.geneCpm,
              })
          }
      },
  
      mounted() { this.generateBoxplot() }
  }
  </script>
  
  <style>
  .boxplot {
      width: 100%;
      min-width: 400px;
  }
  </style>