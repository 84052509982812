const calculateCorrelation = require("calculate-correlation")

var pcorrtest = require( '@stdlib/stats-pcorrtest' );

function intersect(o1, o2){
    return Object.keys(o1).filter(k => Object.hasOwn(o2, k))
}

function linearRegression(x,y){
    var lr = {};
    var n = y.length;
    var sum_x = 0;
    var sum_y = 0;
    var sum_xy = 0;
    var sum_xx = 0;
    var sum_yy = 0;

    for (var i = 0; i < y.length; i++) {

        sum_x += x[i];
        sum_y += y[i];
        sum_xy += (x[i]*y[i]);
        sum_xx += (x[i]*x[i]);
        sum_yy += (y[i]*y[i]);
    } 

    lr['sl'] = (n * sum_xy - sum_x * sum_y) / (n*sum_xx - sum_x * sum_x);
    lr['off'] = (sum_y - lr.sl * sum_x)/n;
    lr['r2'] = Math.pow((n*sum_xy - sum_x*sum_y)/Math.sqrt((n*sum_xx-sum_x*sum_x)*(n*sum_yy-sum_y*sum_y)),2);

    return lr;
}

function filter_non_nan_xy(_x, _y){
    var non_na_xidx = _x.map(i => i !== undefined)
    var non_na_yidx = _y.map(i => i !== undefined)

    var non_na_xyidx = non_na_xidx.map((e, i) => e & non_na_yidx[i])
    var _non_na_x = _x.filter((e, i) => non_na_xyidx[i])
    var _non_na_y = _y.filter((e, i) => non_na_xyidx[i])
    var non_nan_xy = {};
    non_nan_xy['x'] = _non_na_x
    non_nan_xy['y'] = _non_na_y
    return non_nan_xy
}

function create_fitted_line_trace(_x, _y, _color, _des) {
    var fit_from = Math.min(..._x)
    var fit_to = Math.max(..._x)
    var lr = linearRegression(_x, _y);
    var fit = {
        x: [fit_from, fit_to],
        y: [fit_from*lr.sl+lr.off, fit_to*lr.sl+lr.off],
        mode: 'lines',
        type: 'scatter',
        line: {color: _color},
        name: _des
        // name: "R2=".concat((Math.round(lr.r2 * 10000) / 10000).toString())
        };
    return fit
}

function track_to_lines(xTrack, yTrack) {
    const samples_ = Object.keys(yTrack.data).filter(s => Object.keys(xTrack.data).includes(s))

    const x_ = samples_.map(sample => xTrack.data[sample])
    const y_ = samples_.map(sample => yTrack.data[sample])

    // console.log('test scatter 7')
    // console.log(samples)
    // console.log(trackA)
    var non_nan_xy_ = filter_non_nan_xy(x_, y_)
    var non_na_x_ = non_nan_xy_.x
    var non_na_y_ = non_nan_xy_.y
    // console.log(non_na_x_)
    // console.log(non_na_y_)
    var corr_dict = pcorrtest(non_na_x_, non_na_y_)
    var correlation = corr_dict.pcorr
    var corr_pv = corr_dict.pValue
    console.log(non_na_x_)
    console.log(non_na_y_)
    var return_data_dict = {'non_na_x': non_na_x_, 
                            'non_na_y': non_na_y_, 
                            'x':    x_, 
                            'y':    y_, 
                            'corr': correlation.toFixed(3),
                            'pv': corr_pv.toPrecision(3).replace(/e\+?/, ' x 10^')
                        }
    return return_data_dict
}
// var x_data_64 = [your x-data array here];
// var y_data_64 = [your y-data array here];
// var lr = linearRegression(x_data_64, y_data_64);
// //console.log(lr);

// var trace = {x: x_data_64,
//         y: y_data_64,
//         name: "Scatter"
//         };  
// //console.log(trace);

// var fit_from = Math.min(...x_data_64)
// var fit_to = Math.max(...x_data_64)

// var fit = {
// x: [fit_from, fit_to],
// y: [fit_from*lr.sl+lr.off, fit_to*lr.sl+lr.off],
// mode: 'lines',
// type: 'scatter',
// name: "R2=".concat((Math.round(lr.r2 * 10000) / 10000).toString())
// };

//console.log(fit);
// return {data:[trace,fit]};

// TODO: Add datatype of X, Y for adding corresponding units
export default function scatterplot({
    divId,
    trackData,
    trackKeys,
    useZscore,
    trackADT,
    trackBDT
}
) { 
    console.log('test scatter 6')
    console.log(trackKeys)
    let trackA = trackData[trackKeys[2]]
    let trackB = trackData[trackKeys[5]]
    let trackC = trackData[trackKeys[0]]
    let trackD = trackData[trackKeys[3]]
    let trackE = trackData[trackKeys[1]]
    let trackF = trackData[trackKeys[4]]
    const Plotly = window.Plotly
    
    if (!trackA || !trackB) { return }

    // const samples = intersect(trackA, trackB)
    // const samples = Object.keys(trackB.data).filter(s => s in trackA.data)
    // const samples = Object.keys(trackB.data).filter(s => Object.keys(trackA.data).includes(s))

    // const x = samples.map(sample => trackA.data[sample])
    // const y = samples.map(sample => trackB.data[sample])

    // console.log('test scatter 7')
    // console.log(samples)
    // // console.log(trackA)
    // var non_nan_xy = filter_non_nan_xy(x, y)
    // const non_na_x = non_nan_xy.x
    // const non_na_y = non_nan_xy.y
    // console.log(non_na_x)
    // console.log(non_na_y)
    // const correlation_wholedata = calculateCorrelation(non_na_x, non_na_y).toFixed(3)

    var corr_data_whole = track_to_lines(trackA, trackB)
    // var lr_wholedata = linearRegression(non_na_x, non_na_y);
    // console.log(non_na_x, y, correlation_wholedata)
    var linetrace = create_fitted_line_trace(corr_data_whole.non_na_x, 
                                            corr_data_whole.non_na_y, 
                                            '#7FC2D9', 
                        `Best fit line of the whole cohort <br />(Corr:${corr_data_whole.corr}, p-val: ${corr_data_whole.pv})`)

    console.log('test scatter 8')
    // var trace1 = {
    //     x: corr_data_whole.x,
    //     y: corr_data_whole.y,
    //     mode: 'markers',
    //     type: 'scatter',
    //     name: 'Team A',
    //     marker: { size: 12 }
    // }
    const suffixes = {
        'Genes': 'raw count',
        'Air Toxics': 'microgram/m^3'
        // 'rna': 'TPM',
        // 'phospho': 'Log2 intensity',
        // 'proteo': 'Log2 intensity',
        // 'glyco_glyco': 'Log2 intensity',
        // 'phospho_glyco': 'Log2 intensity',
    }
    // const samplesT = Object.keys(trackC.data).filter(s => s in trackD.data)

    // const sc = samplesT.map(sample => trackC.data[sample])
    // const sd = samplesT.map(sample => trackD.data[sample])

    // var non_nan_xyT = filter_non_nan_xy(sc, sd)
    // const non_na_xT = non_nan_xyT.x
    // const non_na_yT = non_nan_xyT.y
    var corr_data_ARDX = track_to_lines(trackC, trackD)
    var linetraceT = create_fitted_line_trace(corr_data_ARDX.non_na_x, 
        corr_data_ARDX.non_na_y, '#DC3220', 
        `Best fit line of the AR-DX cohort<br />(Corr:${corr_data_ARDX.corr}, , p-val: ${corr_data_ARDX.pv})`)

    
    // const samplesN = Object.keys(trackE.data).filter(s => s in trackF.data)

    // const se = samplesN.map(sample => trackE.data[sample])
    // const sf = samplesN.map(sample => trackF.data[sample])

    // var non_nan_xyN = filter_non_nan_xy(se, sf)
    // const non_na_xN = non_nan_xyN.x
    // const non_na_yN = non_nan_xyN.y
    var corr_data_Normal = track_to_lines(trackE, trackF)
    var linetraceN = create_fitted_line_trace(corr_data_Normal.non_na_x, corr_data_Normal.non_na_y, 
        '#005AB5', `Best fit line of the non AR-DX cohort<br />(Corr:${corr_data_Normal.corr}, p-val: ${corr_data_Normal.pv})`)

    var trace2 = {
        x: corr_data_ARDX.x,
        y: corr_data_ARDX.y,
        mode: 'markers',
        type: 'scatter',
        name: 'AR-DX subjects',
        marker: { size: 10, color: '#DC3220' },
        // text: samplesT
        // color: 'red'
    }

    var trace3 = {
        x: corr_data_Normal.x,
        y: corr_data_Normal.y,
        mode: 'markers',
        type: 'scatter',
        name: 'Non AR-DX subjects',
        marker: { size: 10 , color: '#005AB5'},
        // text: samplesN
        // color: 'green'
    }



    // const suffixes = {
    //     'rna': 'TPM',
    //     'phospho': 'Log2 intensity',
    //     'proteo': 'Log2 intensity',
    //     'glyco_glyco': 'Log2 intensity',
    //     'phospho_glyco': 'Log2 intensity',
    // }

    // add # of samples
    // var data = [ trace1 ];
    var data = [linetraceT, linetraceN, linetrace, trace2, trace3];
    
    const xTitle = useZscore ? `${trackA.title} (zscore)` : `${trackA.title} (${suffixes[trackADT]})`
    const yTitle = useZscore ? `${trackB.title} (zscore)` : `${trackB.title} (${suffixes[trackBDT]})`
    // const xTitle = useZscore ? `${trackA.title} (zscore)` : `${trackA.title}`
    // const yTitle = useZscore ? `${trackB.title} (zscore)` : `${trackB.title}`

    var layout = {
        // title: `${trackA.title} - ${trackB.title}<br>pearson correlation: ${correlation}`,
        title: `${trackA.title} vs ${trackB.title}`,
        xaxis: {
            title: xTitle,
            hoverformat: '.2f'
        },
        yaxis: {
            title: yTitle,
            hoverformat: '.2f'
        },
        // legend: {
        //     y: 0.5,
        //     yref: 'paper',
        //     font: {
        //       family: 'Arial, sans-serif',
        //       size: 20,
        //       color: 'grey',
        //     }
        //   },
    };
      
    Plotly.newPlot(divId, data, layout);
}