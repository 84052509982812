<template>
    <div class="network-vis">
      
      <div id="networkmain">
        <network
          class="network"
          ref="network"
          :nodes="this.subNodes"
          :edges="this.subEdges"
          :options="options"
          
        ></network>
        <!-- <img
          id="scream"
          src="north_pole.png"
          style="display: none;"
          alt="Noth Pole"
        /> -->
  
        <!-- <button @click="addNode">Add node</button>
        <button @click="addEdge">Add edge</button>
        <button @click="resetNetwork">Reset Network</button>
        <button @click="removeNode">Remove Node</button>
        <button @click="removeEdge">Remove Edge</button> -->
        <!-- <div class="events">
          <p>
            Network events:
            <br />
            {{networkEvents}}
          </p>
        </div> -->
      </div>
    </div>
  </template>
  
  <script>
  import { Network } from "vue-vis-network";
  // import nodes from "./../refs/nodes_temp.json"
  // import edges from "./../refs/edges_temp.json"
  // import allNodes from "./../refs/ks_proteins.json"
  // import allEdges from "./../refs/ksi_edges.json"
  // import "../vis-network/dist/vis-network.css";
  // import networkvis from "../plotly/networkvis";

  let defNodes = [
    { id: 1, label: "Node 1" },
    { id: 2, label: "Node 2" },
    { id: 3, label: "Node 3. Picture clipped", shape: "image", image: "ups.png", imagePadding:4, size: 25 },
    { id: 4, label: "Node 4 with PNG picture", shape: "image", image: "ups.png", imagePadding:{ top: 10, right: 15, left: 15, bottom: 10}, shapeProperties: { useImageSize: true } },
    { id: 5, label: "Node 5 with SVG picture", shape: "image", image: "device.svg", imagePadding:5, shapeProperties: { useImageSize: true } }
  ]
  let defEdges = [
          { id: 1, from: 1, to: 3, strength:0.2, },
          { id: 2, from: 1, to: 2, strength:0.9, },
          { id: 3, from: 2, to: 4, strength:0.95, },
          { id: 4, from: 2, to: 5, strength:0.2, },
          { id: 5, from: 3, to: 3, strength:0.85, }
        ]

  // let submittedNodes = 
  // let getOneDeg = function(node){
    

  // }
  
  
  export default {
    name: "network-vis",
    components: {Network},
    props: ['subNodes', 'subEdges'],
    // data() {
    data: function (){
      
    return {
      options: {
        nodes: {
          shape: "dot",
          scaling: {
            min: 10,
            max: 30
          },
          font: {
            size: 12,
            face: "Tahoma",
            color:'#fff'
          }
        },
        edges: {
          width: 0.15,
          color: { inherit: "from" },
          smooth: {
            type: "continuous"
          }
        },
        physics: {
          stabilization: false,
          barnesHut: {
            gravitationalConstant: -80000,
            springConstant: 0.001,
            springLength: 200
          }
        },
        interaction: {
          tooltipDelay: 200,
          hideEdgesOnDrag: true
        }
  }
    }
    
    },
    computed: {
        // submittedNodes(){
        //   return this.$store.state.NetworkVis_nodes
        // },

        // allNodes(){
        //   return allNodes
        // },
        // allEdges(){
        //   return allEdges
        // },
        // edges(){
        //   return edges.map(function (edge){
        //     edge.title = edge.strength
        //     edge.physics = edge.strength >0.9
        //     edge.hidden = edge.strength <0.9

        //     return edge
        //   })
        // }
        
    },

    methods: {
      // renderNetworkVis() {
      //   let new_nodes, new_edges;
      //   new_nodes, new_edges = this.networkvis({
      //                     nodes: this.submittedNodes,
      //                     pathways: [],
      //                     allNodes: this.allNodes,
      //                     allEdges: this.allEdges,
      //                   })
      //   let that = this
      //   that.nodes = new_nodes
      //   that.edges = new_edges
      // }
      // drawBg(ctx) {
      //   let mapBgWidth = document.getElementById("scream").width;
      //   let mapBgHeight = document.getElementById("scream").height;
      //   ctx.drawImage(
      //     document.getElementById("scream"),
      //     -mapBgWidth / 2,
      //     -mapBgHeight / 2
      //   );
      // },
      // networkEvent(eventName) {
      //   if (this.networkEvents.length > 500) this.networkEvents = "";
      //   this.networkEvents += `${eventName}, `;
      // },
      // addNode() {
      //   const id = new Date().getTime();
      //   this.network.nodes.push({ id, label: "New node" });
      // },
      // addEdge() {
      //   const n1 = Math.floor(Math.random() * this.network.nodes.length);
      //   const n2 = Math.floor(Math.random() * this.network.nodes.length);
      //   this.network.edges.push({
      //     id: `${this.network.nodes[n1].id}-${this.network.nodes[n2].id}`,
      //     from: this.network.nodes[n1].id,
      //     to: this.network.nodes[n2].id
      //   });
      // },
      // resetNetwork() {
      //   this.network = {
      //     nodes: defNodes.slice(0),
      //     edges: defEdges.slice(0),
      //     options: {}
      //   };
      // },
      // removeNode() {
      //   this.network.nodes.splice(0, 1);
      // },
      // removeEdge() {
      //   this.network.edges.splice(0, 1);
      // }
    }
  };
  </script>
  
  <style>
  #networkmain {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
  
  .wrapper {
    padding: 20px 50px;
    text-align: center;
  }
  .events {
    text-align: left;
    height: 70px;
  }
  .network {
    width: 70vw;
    height: 100vh;
    border: 1px solid #ccc;
    margin: 5px 0;
  }
  
  </style>
  