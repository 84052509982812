<template>
  <div class="boxplot-container">
    <!-- here is the boxplot container
    trackData: {{ k_track_v_boxplotDataKeys }} -->
    <div v-if="trackDataGene">
      Boxplot(s) of gene expressions
      <!-- <div>test</div> -->
      <boxplot
        v-for="(dataKeys, track) in k_track_v_boxplotDataKeysGene"
        :key="track"
        :track="track" 
        :trackData="trackDataGene"
        :dataKeys="dataKeys"
        :useZScore="useZScore"
        :dataType="'rnaSeq'"
        :logScale="logScale"
        :geneCpm="geneCpm"
        
      />
    </div>
    <div v-if="trackDataExposure">
      <!-- <div>test</div> -->
      Boxplot(s) for air toxics
      <boxplot
        v-for="(dataKeys, track) in k_track_v_boxplotDataKeysExposure"
        :key="track"
        :track="track" 
        :trackData="trackDataExposure"
        :dataKeys="dataKeys"
        :useZScore="useZScore"
        :dataType="'exposure'"
        :logScale="logScale"
        :geneCpm="geneCpm"
      />
    </div>
    <!-- <div v-if="trackData">
      <boxplotexposure
        v-for="(dataKeys, track) in k_track_v_boxplotDataKeys"
        :key="track"
        :track="track" 
        :trackData="trackData"
        :dataKeys="dataKeys"
        :useZScore="useZScore"
      />
    </div> -->
    
    <!-- <div v-if="genes && genes.length > 0">
      <boxplot v-for="gene in genes" :key="gene" :tracks="tracks" />
    </div> -->
  </div>
</template>

<script>
// import Boxplotexposure from './BoxplotExposure.vue'
// import Boxplotgene from './BoxplotGene.vue'
import Boxplot from './Boxplot.vue'

export default {
    components: { Boxplot },
    name: "boxplot-container",

    props: ['view'],

    data: () => ({
    }),
    
    computed: {
        useZScore() { return this.$store.state.Boxplot_useZScore },
        logScale() { return this.$store.state.Boxplot_logScale }, 
        geneCpm() { return this.$store.state.Boxplot_geneCpm }, 
        boxplotDataType() { return this.$store.state.Boxplot_dataType },
        k_track_v_boxplotDataKeysGene() { 
          console.log('testboxplot2')
          let res = {}
          this.genes.forEach(gene => {
            let geneTracks
            console.log(this.trackDataGeneRaw)
            if (this.boxplotDataType == 'All') {
              geneTracks = Object.entries(this.trackDataGene)
                .filter(([, data]) => !data.clinical && data.omics === gene )
            } else {
              geneTracks = Object.entries(this.trackDataGene)
                .filter(([, data]) => 
                  !data.clinical && 
                  data.omics === gene 
                )
            }
            geneTracks.forEach(([track, data]) => {
              if (!(data.parent in res)) { res[data.parent] = [] }
              res[data.parent].push(track)
            })
          })
          console.log('boxplot gene res', res)
          return res
        },
        k_track_v_boxplotDataKeysExposure() { 
          // console.log('testboxplot3')
          let res = {}
          this.exposures.forEach(exposure => {
            let exposureTracks
            // console.log(this.trackDataExposureRaw)
            // if (this.boxplotDataType == 'All') {
            //   exposureTracks = Object.entries(this.trackDataExposure)
            //     .filter(([, data]) => !data.clinical && data.omics === exposure )
            // } else {
            //   exposureTracks = Object.entries(this.trackDataExposure)
            //     .filter(([, data]) => 
            //       !data.clinical && 
            //       data.omics === exposure  
            //     )
            // }
            exposureTracks = Object.entries(this.trackDataExposure)
                .filter(([, data]) => !data.clinical && data.omics === exposure )
            // console.log(Object.entries(this.trackDataExposure))
            // console.log(exposureTracks)
            exposureTracks.forEach(([track, data]) => {
              if (!(data.parent in res)) { res[data.parent] = [] }
              // console.log('213', data.parent)
              res[data.parent].push(track)
            })
          })
          // console.log('before generate')
          // console.log(res)
          // console.log(this.trackDataExposureNormalized)
          // console.log(this.trackDataExposure)
          // console.log(this.trackDataGeneNormalized)
          // console.log(this.boxplotDataType)
          // console.log(this.useZScore)
          return res
        },
    //     boxplotDataType() { return this.$store.state.boxplotDataType },
    //     boxplotView() { return this.$store.state.boxplotView },
        genes() { 
          return this.$store.state.Boxplot_gene_genes },
        exposures() { 
          return this.$store.state.Boxplot_exposure_exposures },
        trackDataGeneNormalized() {return this.$store.state.Boxplot_gene_data },
        trackDataGeneRaw() {return this.$store.state.Boxplot_gene_data_raw },
        trackDataGene() { return this.useZScore ?
          this.trackDataGeneNormalized :
          this.trackDataGeneRaw
        },
        trackDataExposureNormalized() {return this.$store.state.Boxplot_exposure_data },
        trackDataExposureRaw() {return this.$store.state.Boxplot_exposure_data_raw },
        trackDataExposure() { 
          return this.useZScore ?
          this.trackDataExposureNormalized :
          this.trackDataExposureRaw
        },
        // trackData() { return this.useZScore ?
        //   this.trackDataNormalized :
        //   this.trackDataRaw
        // },
    //     
    },
    mounted() {
    },
  }
</script>

<style>
.boxplot-container {
    width: 100%;
}
</style>