<template>
  <div class="input-barplot">
    <v-autocomplete
        v-model="barplotExposures"
        :items="exposures"
        outlined
        chips
        deletable-chips
        small-chips
        :menu-props="{closeOnContentClick: true}"
        dense
        :label="`Select up to ${maxExposures} air toxics`"
        multiple
        clearable
    >
    </v-autocomplete>
    <div
      @click="submitBarplot"
    >
    <v-btn
        v-if="barplotExposures.length > 0"
        :loading="loaderBarplot"
        :disabled="loading || !valid"
        color="blue-grey"
        class="ma-2 white--text"
      >
        
        {{ valid ? 'Render barplots' : 'Select fewer air toxics' }}
    </v-btn>
    </div>
  </div>
</template>

<script>
// import InputBarplotCustomize from './InputBarplotCustomize.vue'

export default {
    name: "input-barplot",

    computed: {
        availableExposure() { 
          // console.log('barplot test1')
          return this.$store.state.availableExposureQuantile 
          
        },
        // availableGene() { return this.$store.state.availableGene },
        loaderBarplot() { return this.$store.state.loaderBarplot },

        exposures() { 
            const available = Object.keys(this.availableExposure)
            available.sort()
            return available
        },
        valid() { 
          const exposuresValidBool = this.barplotExposures.length <= this.maxExposures && this.barplotExposures.length > 0
          // const genesValidBool = this.barplotGenes.length <= this.maxGenes && this.barplotGenes.length > 0
          return  exposuresValidBool
        },
    },
    
    data: () => ({
        barplotExposures: ['PHOSPHINE'],
        loading: false,
        maxExposures: 10,
        // barplotGenes: ['PRB1', 'PRTG'],
        // maxGenes: 200,
    }),


    methods: {
        submitBarplot() {
            console.log('testSubmitBarplot')
            this.$store.dispatch('getTracksExposureQuantile', 
            { 
              view: 'Barplot', 
              exposures: this.barplotExposures 
            }
          )
          console.log(this.barplotExposures)
          console.log(this.$store.state.Barplot_data_raw)
          // this.$store.dispatch('getTracks', 
          //   { 
          //     view: 'Barplot_gene', 
          //     genes: this.barplotGenes 
          //   }
          // )
          // console.log('testbarplot1')
          // console.log(this.$store.state.Barplot_genes)
          // console.log(this.$store.state.Barplot_exposures)
          // console.log(this.$store.state.Barplot_gene_data)
          // console.log('testSubmitGenes2')
        },
    },

    mounted() { this.submitBarplot() }
}
</script>

<style>
.input-barplot {
    margin: 10px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.ttest-descriptor {
  width: 100%;
  margin: 10px;
  font-size: 0.8em;
}
</style>