<template>
  <div class="input-correlation">
    
    <v-autocomplete
        v-model="correlationGenes"
        :items="genes"
        outlined
        chips
        deletable-chips
        small-chips
        :menu-props="{closeOnContentClick: true}"
        dense
        :label="`Select up to ${maxGenes} genes`"
        multiple
        clearable
    >
    </v-autocomplete>
    <v-autocomplete
        v-model="correlationExposures"
        :items="exposures"
        outlined
        chips
        deletable-chips
        small-chips
        :menu-props="{closeOnContentClick: true}"
        dense
        :label="`Select up to ${maxExposures} air toxics`"
        multiple
        clearable
    >
    </v-autocomplete>
    <div
      @click="submitCorrelation"
    >
    <v-btn
        v-if="correlationExposures.length > 0 || correlationGenes.length > 0"
        :loading="loaderCorrelation"
        :disabled="loading || !valid"
        color="blue-grey"
        class="ma-2 white--text"
      >
        
        {{ valid ? 'Render correlation map' : 'Select fewer genes/air toxics' }}
    </v-btn>
    </div>
    <!-- <div class="ttest-descriptor"> -->
      <!-- Note: p-values calculated with t-test from their raw values -->
       <!-- <br>  -->
       <!-- <a href="https://www.npmjs.com/package/ttest">npm ttest package
       </a> -->
    <!-- </div> -->

    <!-- <input-correlation-customize /> -->
    <!-- <div>test</div> -->
  </div>
</template>

<script>
// import InputCorrelationCustomize from './InputCorrelationCustomize.vue'

export default {
    // components: { InputCorrelationCustomize },
    name: "input-correlation-map",

    computed: {
        availableExposure() { return this.$store.state.availableExposure },
        availableGene() { return this.$store.state.availableGene },
        loaderCorrelation() { return this.$store.state.loaderCorrelation },
        genes() { 
            const available = Object.keys(this.availableGene)
            available.sort()
            return available
        },
        exposures() { 
            const available = Object.keys(this.availableExposure)
            available.sort()
            return available
        },
        valid() { 
          const exposuresValidBool = (this.correlationExposures.length <= this.maxExposures) && (this.correlationExposures.length > 0)
          const genesValidBool = (this.correlationGenes.length <= this.maxGenes) && (this.correlationGenes.length > 0)
          return  exposuresValidBool || genesValidBool 
        },
    },
    
    data: () => ({
        correlationExposures: ['COBALT-COMPOUNDS', 'ACRYLIC-ACID'],
        loading: false,
        maxExposures: 20,
        correlationGenes: ['PRB1', 'PRTG'],
        maxGenes: 20,
    }),


    methods: {
        submitCorrelation() {
            console.log('testSubmitCorrelationMultimodal')
          //   this.$store.dispatch('getTracksExposure', 
          //   { 
          //     view: 'Correlation_exposure', 
          //     exposures: this.correlationExposures 
          //   }
          // )
          // // console.log(this.correlationExposures)
          // this.$store.dispatch('getTracks', 
          //   { 
          //     view: 'Correlation_gene', 
          //     genes: this.correlationGenes 
          //   }
          // )
          this.$store.dispatch('getTrackGNECorrData',
            {
              view: 'Correlation',
              gne: [this.correlationGenes, this.correlationExposures],
              x_type: 'Genes',
              y_type: 'Air Toxics',
            }

          )
          console.log('testcorrelation0')
          // this.$store.
          // TODO: write correlation plot
          this.$store.dispatch('setCorrelationTracks',{
            Correlation_tracks: {'x': this.correlationGenes,
                                'y': this.correlationExposures}
          })
          console.log('testcorrelation1')
          console.log(this.$store.state.Correlation_data)
          console.log(this.$store.state.Correlation_tracks)
          // console.log(this.$store.state.Correlation_genes)
          // console.log(this.$store.state.Correlation_exposures)
          // console.log(this.$store.state.Correlation_gene_data)
          // console.log('testSubmitGenes2')
        },
    },

    mounted() { this.submitCorrelation() }
}
</script>

<style>
.input-correlation {
    margin: 10px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.ttest-descriptor {
  width: 100%;
  margin: 10px;
  font-size: 0.8em;
}
</style>