export default function yAxis(){
  return {
    fixedrange: true,
    autorange: true,
    showgrid: false,
    zeroline: false,
    showticklabels: true,
    ticks: '',
    // hoverformat: '.2f',
    automargin: true,
    showline: false,
    autotick: true,
  }
}