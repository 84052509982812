<template>
	<!-- <main id="Text"> -->
    <div class="plot-content-page">
      
        <div class="center-text">
            
          <br>
                <!-- <div > -->
    <h2 align="center" >Data visualizations tools</h2>
    <!-- </div> -->
<br>
<div>
<table class="center-one-each-row">
    <thead>
      <tr>
      </tr>
    </thead>
    <tbody>
      <tr class="center-photo" align="center" >
         <td v-for="(user, index) in plots" :key="index">
            <div class="list-complete-item">
            
              <div class="overlay">
                        
                  <div class="hover-text" @click="jumpToView(user.view)">
                    {{ user.name }}
                  </div>
                
              </div>
            <img class="image" align="center" :src="user.plotImage" 
              alt="Profile Image"
              @click="jumpToView(user.view)">
            </div>
            <p>
              <v-btn @click="jumpToView(user.view)">
                {{ user.name }}
              </v-btn>
          </p>
          <br>
         </td>
      </tr>
    </tbody>
  </table>
</div>
</div>
</div>
</template>

<script>
export default {
    name: "plot-content-page",
    props: ['view'],
    methods: {
        jumpToView(view){
            console.log('before clicking', this.$store.view)
            this.$store.dispatch('setView', { view })
            console.log('after clicking', this.$store.view)
        }
    },
    data() {
        return {
            plots: [
            {
              name: "Barplot",
              plotImage: require("../assets/img/plots/Barplot.png"),
              view: "Barplot"
            },
            {
              name: "Boxplot",
              plotImage: require("../assets/img/plots/Boxplot.png"),
              view: "Boxplot"
            },
            {
              name: "Heatmap",
              plotImage: require("../assets/img/plots/Heatmap.png"),
              view: "Heatmap"
            },
            {
              name: "Correlation matrix",
              plotImage: require("../assets/img/plots/Correlation.png"),
              view: "Correlation"
            },
            {
              name: "Scatter plot",
              plotImage: require("../assets/img/plots/Scatterplot.png"),
              view: "Scatterplot"
            },
            {
              name: "Network",
              plotImage: require("../assets/img/plots/Network.png"),
              view: "Network"
            },
        ]
        }
    },
}
</script>

<style scoped>
.plot-content-page {
  /* display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto; */
  /* width: 50%; */
  /* display: flex;
  flex-direction: column; */
  /* min-height: 100%; */
  flex: 0 0 100%;    
  /* NEW */
  display: flex;
  max-width: 50%;
  /* overflow-y: auto; */
  /* display: flex; */
  /* flex: 1; */
}

.center-text {
  font-size: min(2vm, 30px);
  width: 70%;
  /* position: center;
  text-align: center; */
  /* align-items: center;
  padding-left: 12%; 
  padding-right: 12%; */
  /* max-width: 70vw; */
  /* width: 50vw; */
  /* display: block; */
  /* display: flex;
  flex-wrap: wrap; */
  /* padding-left: 12%; 
  padding-right: 12%; */
  /* display: block; */
  /* flex: 1; */
  justify-content: center;
  /* display: flex;
    flex-direction: column; */
    /* overflow: scroll; */
  /* float: left; */
  /* justify-content: space-evenly */
  /* justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto; */
}

.center-one-each-row {
  margin-left: auto;
  margin-right: auto;

}

.center-photos {
  
  /* margin-left: auto;
  margin-right: auto; */
  /* padding: 0; */
  /* padding-left: 11%; 
  padding-right: 11%; */
    /* margin-left: auto;
    margin-right: auto;
    list-style-type: none; */
    /* display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; */
    /* display: flex; */
    
    /* -webkit-flex-flow: row wrap; */
    /* flex-direction: row wrap; */
    /* flex-wrap: wrap; */
    
    /* justify-content: space-evenly; */
}

.single-photo {

}


.hover-text {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.list-complete-item {
  position: relative;
  width: 18vw;
  min-width: 160px;
  max-width: 200px;
  aspect-ratio:  235/180;
  /* margin-left: auto;
    margin-right: auto;
    display: block; */
  /* font-size: 1.3em; */
}

.list-complete-item .image {
    /* /* display: block; */
  width: 18vw;
  min-width: 160px;
  max-width: 200px;
  aspect-ratio: 235/180;
  /* height: 235px; */
  object-fit: cover; 
  object-position: 50% 50%;
}


.logo {
  display: block;
  height: 5vh;
  min-height: 100px;
  max-height: 130px;
  margin-left: auto;
  margin-right: auto;
    /* object-fit: cover; 
    object-position: 50% 50%; */
}


.list-complete-item .overlay, .list-complete-item .image, .overlay {
  width: 10vw;
  min-width: 160px;
  max-width: 200px;
  aspect-ratio: 235/180;
  display: block;
    /* width: auto; */
    /* height: auto; */
  /* height: 235px; */
}
.list-complete-item .overlay {
    /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* height: 100%;
  width: 100%; */
  width: 10vw;
  min-width: 160px;
  max-width: 200px;
  aspect-ratio: 235/180;
  object-position: 50% 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: #1F497E;
    /* background-color: #008CBA; */
}
.list-complete-item:hover .overlay {
    opacity: 0.8;
}

.ppl-org-names{
  color: #219EBC;
  /* font-size: max(0.9vw, 20px); */
  font-size: max(0.9vw, 20px);
}

.ppl-org-names-smaller{
  color: #219EBC;
  /* font-size: max(0.9vw, 20px); */
  font-size: max(0.7vw, 16px);
}


.overlay {
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  /* -webkit-transform: translate(-50%, -50%); */
  /* transform: translate(20%, 0);
  
  opacity: 0; */
  margin-left: auto;
  margin-right: auto;
  transition: 0.5s ease;
  background-color: #1F497E;
  /* background-color: #008cba; */
}



.center-photos>td{
  flex: 1 0 20vw;
}


/* .single-photo>tr{
  flex: 1 0 50vw;
} */
</style>


