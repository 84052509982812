<template>
  <div class="input-scatterplot">
      <div style="margin-bottom: 10px; padding: 10px; background: #ebebeb">
        Render scatterplots of gene expression and air toxics abundance. <br><br>
        * The p-values of lines are calculated based on coefficient of correlation
      </div>

      <v-select
        :items="dataTypes"
        item-value="dataType"
        item-text="text"
        label="X-axis data type:"
        v-model="scatterXDatatype"
        @onchange="scatterXDatatype"
      >
      </v-select>
      <div class="x-axis-gene" v-if="scatterXDatatype == 'Genes'">
        <v-autocomplete
          v-model="scatterplotXdata['Genes']"
          :items="genes"
          outlined
          :menu-props="{closeOnContentClick: true}"
          dense
          :label="`Select up to ${maxGenes} genes`"
          clearable
          multiple
          chips
          deletable-chips
          small-chips
      ></v-autocomplete>
    </div>
      <div class="x-axis-exposure" v-if="scatterXDatatype == 'Air Toxics'">
        <v-autocomplete
        v-model="scatterplotXdata['Air Toxics']"
        :items="exposures"
        outlined
        :menu-props="{closeOnContentClick: true}"
        dense
        :label="`Select up to ${maxExposures} air toxics`"
        clearable
        multiple
        chips
        deletable-chips
        small-chips
    >
      </v-autocomplete>
    </div>
    <v-select
        :items="dataTypes"
        item-value="dataType"
        item-text="text"
        label="Y-axis data type:"
        v-model="scatterYDatatype"
        @onchange="scatterYDatatype"
      >
    </v-select>
    <div class="y-axis-gene" v-if="scatterYDatatype == 'Genes'">
        <v-autocomplete
          v-model="scatterplotYdata['Genes']"
          :items="genes"
          outlined
          :menu-props="{closeOnContentClick: true}"
          dense
          :label="`Select up to ${maxGenes} genes`"
          clearable
          multiple
          chips
          deletable-chips
          small-chips
      ></v-autocomplete>
    </div>
      <div class="y-axis-exposure" v-if="scatterYDatatype == 'Air Toxics'">
        <v-autocomplete
        v-model="scatterplotYdata['Air Toxics']"
        :items="exposures"
        outlined
        :menu-props="{closeOnContentClick: true}"
        dense
        :label="`Select up to ${maxExposures} air toxics`"
        clearable
        multiple
        chips
        deletable-chips
        small-chips
    >
      </v-autocomplete>
    </div>

    <v-btn
      elevation="2"

      color="blue lighten-2"
      dark
      :disabled="!valid"
      @click="setScatterplotTracks"
    > 
      Submit
    </v-btn>
    <div v-if="submitted">
    <v-switch
      v-model="useZScore"
      label="Use z-scores"
      color="red darken-3"
      hide-details
    ></v-switch>
    </div>
    <!-- <input-scatterplot-customize /> -->
  </div>
</template>

<script>
// import InputScatterplotCustomize from './InputScatterplotCustomize.vue'

export default {
    components: { 
      // InputScatterplotCustomize  
    },
    
    name: "input-scatterplot",

    computed: {
        availableGenes() { return this.$store.state.availableGene },
        availableExposures() { return this.$store.state.availableExposure },
        // loaderBoxplot() { return this.$store.state.loaderBoxplot },
        genes() { 
            const available = Object.keys(this.availableGenes)
            available.sort()
            return available
        },
        exposures() { 
            const available = Object.keys(this.availableExposures)
            available.sort()
            return available
        },
        valid() { 
          let datatype_bool = this.scatterXDatatype != null && this.scatterYDatatype != null
          let x_axis_bool = this.scatterplotXdata['Genes'].length > 0 && this.scatterplotXdata['Air Toxics'].length > 0
          let y_axis_bool = this.scatterplotYdata['Genes'].length > 0 && this.scatterplotYdata['Air Toxics'].length > 0

          return datatype_bool && x_axis_bool && y_axis_bool
        },
        
      //   geneATracks() {
      //   return Object.fromEntries(Object.entries(this.trackData).filter(([track, data]) => {
      //     return data.gene === this.geneA
      //   }))
      // },
      // geneATrackList() { return Object.keys(this.geneATracks) },
      // geneBTracks() {
      //   return Object.fromEntries(Object.entries(this.trackData).filter(([track, data]) => {
      //     return data.gene === this.geneB
      //   }))
      // },
      // geneBTrackList() { return Object.keys(this.geneBTracks) },
      // trackData() { 
      //   return this.$store.state.Scatterplot_data 
      // },
      useZScore: {
        get() { return this.$store.state.Scatterplot_useZScore },
        set(v) { 
          this.$store.dispatch(
            'setUseZScore',
            { 
              view: 'Scatterplot',
              useZScore: v 
            }
          )}
      },
      },

    
    data: () => ({
        loading: false,
        scatterplotXdata: {'Genes': ['TLE4'],
                            'Air Toxics': ['HEXACHLOROCYCLOPENTADIENE']},
        scatterplotYdata: {'Genes': ['TLE4'],
                            'Air Toxics': ['HEXACHLOROCYCLOPENTADIENE']},
        // scatterplotExposureX: ['HEXACHLOROCYCLOPENTADIENE'],
        // scatterplotGeneY: ['TLE4'],
        // scatterplotExposureY: ['HEXACHLOROCYCLOPENTADIENE'],
        submitted: false,
        maxExposures: 5,
        dataTypes: ['Genes', 'Air Toxics'],
        scatterXDatatype: null,
        scatterYDatatype: null,
        // boxplotGenes: ['PRB1', 'PRTG'],
        maxGenes: 5,
    }),


    methods: {
        // submitScatterplotGenes() {
        //     this.$store.dispatch('getTracks', 
        //     { 
        //       view: 'Scatterplot', 
        //       genes: [this.scatterplotGeneA],
        //     })
        //     this.$store.dispatch('getTracksExposure', 
        //     { 
        //       view: 'Scatterplot', 
        //       genes: [this.scatterplotExposure],
        //     })
        //     this.setScatterplotTracks()
        // },
        setScatterplotTracks() {
            // this.$store.dispatch('getTracks', 
            // { 
            //   view: 'Scatterplot', 
            //   genes: [this.scatterplotGeneA],
            // })
            // this.$store.dispatch('getTracksExposure', 
            // { 
            //   view: 'Scatterplot', 
            //   genes: [this.scatterplotExposure],
            // })
            console.log()
            console.log('testscatter1')
            // console.log([this.scatterplotGene, this.scatterplotExposure])
            this.$store.dispatch('getTrackGNEData', {
              view: 'Scatterplot',
              gne: [this.scatterplotXdata, this.scatterplotYdata],
              x_type: this.scatterXDatatype,
              y_type: this.scatterYDatatype
            })
            // this.setScatterplotTracks()
          this.$store.dispatch('setScatterplotTracks', {
            Scatterplot_tracks: [ this.scatterplotXdata, this.scatterplotYdata ] 
          })
          this.$store.dispatch('setScatterplotDT', {
            Scatterplot_datatypes: [ this.scatterXDatatype, this.scatterYDatatype]
          })
          console.log('datatypes:', 
                  this.$store.state.Scatterplot_dataX_DT, 
                  this.$store.state.Scatterplot_dataY_DT)
          this.submitted = true
        },
    },
    watch: {
      trackData() {}
    },
    mounted() {}
}
</script>

<style>
.input-scatterplot {
    margin: 10px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.ttest-descriptor {
  width: 100%;
  margin: 10px;
  font-size: 0.8em;
}
</style>