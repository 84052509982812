<template>
    <div class="boxplot-cpg">
        <!-- test -->
        <!-- test 123 -->
        <div :id="this.divId">
        </div>
    </div>
    <!-- <div class="boxplot">
        <div :id="divId">
        </div>
    </div> -->
</template>
  
  <script>
  import boxplotcpgly from '../../plotly/boxplotcpgly'
  
  export default {
      name: "boxplot-cpg",
  
      props: [ 'plotData', 'divId' ],
      
      data: () => ({
          view: 'BoxplotCPG',
      }),
  
      computed: {
        // divId() { 
        //     //   console.log('usds'.split(' ').join('-'))
        //       return `${this.plotData['CpGName']}-${this.plotData['AirToxicName']}-boxplotCPG` },
          
      },
  
      watch: {
        plotData() { this.generateBoxplot() },
      },
  
      methods: {
          generateBoxplot() {
              console.log('gen boxplotcpg', this.plotData) 
            //   if (!this.dataKeys) { return }
            boxplotcpgly({
                divId: this.divId,
                plotData: this.plotData,
              })
          }
      },
  
      mounted() { this.generateBoxplot() }
  }
  </script>
  
  <style>
  .boxplot {
      width: 100%;
      min-width: 400px;
  }
  </style>