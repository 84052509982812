<template>
    <div class="network-vis-container">
      <network-vis 
      :subNodes="this.subsetNodes" 
      :subEdges="this.subsetEdges"/>
    </div>
  </template>
  
  <script>
  import NetworkVis from "./NetworkVis.vue";
  // import { Network } from "vue-vis-network";
  // import nodes from "./../refs/nodes_temp.json"
  // import edges from "./../refs/edges_temp.json"
  import allNodes from "./../refs/ks_proteins.json"
  import allEdges from "./../refs/ksi_edges.json"
  import netvisly from "../plotly/netvisly.js"
  // import "../vis-network/dist/vis-network.css";
  // import networkvis from "../plotly/networkvis";
  // let submittedNodes = 
  // let getOneDeg = function(node){
    

  // }
  
  
  export default {
    name: "network-vis-container",
    components: {NetworkVis},
    // data() {
    data() {
      return {}
    },
    props: ['view'],
    computed: {
        submittedNodes(){
          return this.$store.state.NetworkVis_nodes
        },
        subsetNodes(){
          // console.log('nodes', allNodes)
          // return allNodes
          return this.$store.state.NetworkVis_subsetNodes;
        },
        subsetEdges(){
          // console.log('edges', allEdges)
          // return allEdges
          return this.$store.state.NetworkVis_subsetEdges;
        },
        allNodes(){
          return allNodes
        },
        allEdges(){
          return allEdges
        },
        // edges(){
        //   return edges.map(function (edge){
        //     edge.title = edge.strength
        //     edge.physics = edge.strength >0.9
        //     edge.hidden = edge.strength <0.9

        //     return edge
        //   })
        // }
        
    },
    watch: {
      // submittedNodes() { this.renderNetworkVis() },
      
    },

    methods: {
      // renderNetworkVis() {
      //   let new_nodes, new_edges;
      //   new_nodes, new_edges = netvisly({
      //                     nodes: this.submittedNodes,
      //                     pathways: [],
      //                     allNodes: this.allNodes,
      //                     allEdges: this.allEdges,
      //                   })
      //   console.log('vis subset nodes 0', this.$store.state.NetworkVis_subsetNodes)
      //   console.log('vis subset nodes 1', new_nodes)

      //   this.$store.dispatch('setViewAttr', {
      //       view: 'NetworkVis',
      //       attrKey: 'subsetNodes',
      //       attrVal: new_nodes
      //   })
      //   this.$store.dispatch('setViewAttr', {
      //       view: 'NetworkVis',
      //       attrKey: 'subsetEdges',
      //       attrVal: new_edges
      //   })
      //   console.log('vis subset nodes 2', this.$store.state.NetworkVis_subsetNodes)
      //   // let that = this
      //   // that.nodes = new_nodes
      //   // that.edges = new_edges
      // }
      // drawBg(ctx) {
      //   let mapBgWidth = document.getElementById("scream").width;
      //   let mapBgHeight = document.getElementById("scream").height;
      //   ctx.drawImage(
      //     document.getElementById("scream"),
      //     -mapBgWidth / 2,
      //     -mapBgHeight / 2
      //   );
      // },
      // networkEvent(eventName) {
      //   if (this.networkEvents.length > 500) this.networkEvents = "";
      //   this.networkEvents += `${eventName}, `;
      // },
      // addNode() {
      //   const id = new Date().getTime();
      //   this.network.nodes.push({ id, label: "New node" });
      // },
      // addEdge() {
      //   const n1 = Math.floor(Math.random() * this.network.nodes.length);
      //   const n2 = Math.floor(Math.random() * this.network.nodes.length);
      //   this.network.edges.push({
      //     id: `${this.network.nodes[n1].id}-${this.network.nodes[n2].id}`,
      //     from: this.network.nodes[n1].id,
      //     to: this.network.nodes[n2].id
      //   });
      // },
      // resetNetwork() {
      //   this.network = {
      //     nodes: defNodes.slice(0),
      //     edges: defEdges.slice(0),
      //     options: {}
      //   };
      // },
      // removeNode() {
      //   this.network.nodes.splice(0, 1);
      // },
      // removeEdge() {
      //   this.network.edges.splice(0, 1);
      // }
    }
  };
  </script>
  
  <style>
  /* #networkmain {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
   */
  .wrapper {
    padding: 20px 50px;
    text-align: center;
  }
  .events {
    text-align: left;
    height: 70px;
  }
  .network {
    width: 50vw;
    height: 100vh;
    border: 1px solid #ccc;
    margin: 5px 0;
  }
  
  </style>
  