import ttest from 'ttest'


export default function generateBoxplot({
  divId,
  track,
  trackData,
  dataKeys,
  stratifyBy,
  useZScore,
  logScale,
  dataType,
  geneCpm,
}) {
  console.log('testboxploly')  
  const ttest_option = {valEqual: false,
                  }  
    if (!trackData) { return }
    console.log(trackData)
    const Plotly = window.Plotly

    let groupA = trackData[dataKeys[0]]
    let groupB = trackData[dataKeys[1]]
    console.log('test datakey')
    console.log(dataKeys[0])
    if (!groupA || !groupB) { return }

    let groupASamples = Object.keys(groupA.data)
    let groupBSamples = Object.keys(groupB.data)

    groupASamples.sort()
    groupBSamples.sort()
    let rawtraceA = groupASamples.map(s => groupA.data[s])
    let rawtraceB = groupBSamples.map(s => groupB.data[s])
    let traceA = [];
    let traceB = [];
    // let dataType = '';
    if (logScale && (!(useZScore)) && (dataType==='rnaSeq')) {
      traceA = groupASamples.map(s => Math.log2(groupA.data[s]+1))
      traceB = groupBSamples.map(s => Math.log2(groupB.data[s]+1))
      
    } else {
      traceA = groupASamples.map(s => groupA.data[s])
      traceB = groupBSamples.map(s => groupB.data[s])
    }
    let trace1 = {
      y: traceA,
      name: groupA.label,
      marker: {
        color: '#DC3220'
      },
      type: 'box',
      boxpoints: 'all',
      jitter: 0.4,
      pointpos: 0,
      text: groupASamples
    }

    let trace2 = {
      y:  traceB,
      name: groupB.label,
      marker: {
        color: '#005AB5'
      },
      type: 'box',
      boxpoints: 'all',
      jitter: 0.4,
      pointpos: 0,
      text: groupBSamples
    }
    
    var suffixes = {
      'rnaSeq': 'raw count',
      'exposure': 'microgram/m^3'
      // 'rna': 'TPM',
      // 'phospho': 'Log2 intensity',
      // 'proteo': 'Log2 intensity',
      // 'glyco_glyco': 'Log2 intensity',
      // 'phospho_glyco': 'Log2 intensity',
  }
    if (geneCpm) {
      suffixes['rnaSeq'] = 'CPM'
    }
    // const dataType = track.split(' ')[1]  
    
    let y_axis_title = ''
    if (useZScore){
      y_axis_title = 'z-score'
    } else if (logScale && ((dataType=='rnaSeq'))) {
      y_axis_title = 'log of ' + suffixes[dataType]
    } else {
      y_axis_title = suffixes[dataType]
    }
    let layout = {
      title: track,
      yaxis: {
        title: y_axis_title,
        zeroline: false,
        hoverformat: '.2f'
      },
      xaxis: {
        title: stratifyBy ? stratifyBy : 'label',
      },
      boxmode: stratifyBy ? 'group' : '',
    };

    if (stratifyBy) {
      let groupAStratifyLabels = groupASamples.map(s => trackData[stratifyBy].data[s])
      let groupBStratifyLabels = groupBSamples.map(s => trackData[stratifyBy].data[s])
  
      trace1.x = groupAStratifyLabels
      trace2.x = groupBStratifyLabels
      
      const categories = trackData[stratifyBy].clinicalData.trackDetails.map(el => el.label)
      layout.xaxis.categoryarray = categories

      let annotations = []

      categories.forEach(category => {
        let groupACategorySamples = groupASamples.filter(s => trackData[stratifyBy].data[s] === category)
        let groupBCategorySamples = groupBSamples.filter(s => trackData[stratifyBy].data[s] === category)

        let groupACategoryValues = groupACategorySamples.map(s => groupA.data[s]).filter(el => el)
        let groupBCategoryValues = groupBCategorySamples.map(s => groupB.data[s]).filter(el => el)

        let pValue = ttest(groupACategoryValues, groupBCategoryValues).pValue().toExponential().toString()

        if (pValue.includes('e')) {
          const pValArr = pValue.split('e')
          const val = pValArr[0].slice(0,4)
          const exp = pValArr[1]
          pValue = `${val}e${exp}`
        }

        annotations.push({
          x: category,
          y: 1.1,
          xref: 'x',
          yref: 'paper',
          text: pValue.includes('NaN') ? '' : `pValue:<br>${pValue}`,
          showarrow: false,
        })

        layout.annotations = annotations
      })
    } else {
      let groupAValues = rawtraceA.filter(el => el)
      let groupBValues =  rawtraceB.filter(el => el)

      let pValue = ttest(groupAValues, groupBValues).pValue().toExponential().toString()
      if (pValue.includes('e')) {
        const pValArr = pValue.split('e')
        const val = pValArr[0].slice(0,4)
        const exp = pValArr[1]
        pValue = `${val}e${exp}`
      }
      layout.annotations = [
        {
          x: 0.5,
          y: 1.1,
          xref: 'paper',
          yref: 'paper',
          text: `pValue: ${pValue}`,
          showarrow: false,
        }
      ]
    }

    var data = [trace1, trace2]

    let config = {
      responsive: true,
    }
    console.log('check boxplotjs divid', divId)
    Plotly.newPlot(divId, data, layout, config);
}