<template>
  <div class="barplot-container">
    test
      <barplotexposure
        v-for="(dataKeys, track) in k_track_v_barplotDataKeysExposure"
        :key="track"
        :track="track" 
        :trackData="trackDataExposure"
        :dataKeys="dataKeys"
      />

  </div>
</template>

<script>
import Barplotexposure from './BarplotExposure.vue'
// import Barplotgene from './BarplotGene.vue'
// import Barplot from './Barplot.vue'

export default {
    components: { Barplotexposure },
    name: "barplot-container",

    props: ['view'],

    data: () => ({
    }),
    
    computed: {
      
        k_track_v_barplotDataKeysExposure() { 
          // console.log('testbarplot3')
          let res = {}
          this.exposures.forEach(exposure => {
            let exposureTracks = Object.entries(this.trackDataExposure)
                .filter(([, data]) => !data.clinical && data.omics === exposure )
            exposureTracks.forEach(([track, data]) => {
              if (!(data.parent in res)) { res[data.parent] = [] }
              res[data.parent].push(track)
            })
          })
          console.log('before generate')
          console.log(res)
          console.log(this.trackDataExposure)
          return res
        },
    //     barplotDataType() { return this.$store.state.barplotDataType },
    //     barplotView() { return this.$store.state.barplotView },
        // genes() { 
        //   return this.$store.state.Barplot_gene_genes },
        exposures() { 
          return this.$store.state.Barplot_exposures 
        },
        
        // trackDataExposureNormalized() {return this.$store.state.Barplot_exposure_data },
        // trackDataExposureRaw() {return this.$store.state.Barplot_exposure_data_raw },
        trackDataExposure() { 
          // return this.useZScore ?
          // this.trackDataExposureNormalized :
          // this.trackDataExposureRaw
          return this.$store.state.Barplot_data_raw
        },
        // trackData() { return this.useZScore ?
        //   this.trackDataNormalized :
        //   this.trackDataRaw
        // },
    //     
    },
    mounted() {
    },
  }
</script>

<style>
.barplot-container {
    width: 100%;
}
</style>