export default function xAxis() {
  return {
    visible: false,
    fixedrange: true,
      autorange: true,
      showgrid: false,
      zeroline: false,
      showticklabels: false,
      ticks: 'inside',
      tickfont: {
        size: 1  // Set the font size of x-axis tick labels
      },
      // title: 'y-label',
      // hoverformat: '.2f',
      automargin: true,
    showline: false,
    // title: ''
    autotick: true,
    tickvals: [0, 0.1]
      // showticklabels: false
  }
}